<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}

.button-panel {
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder
    size="mini"
    :zIndex="zIndex"
    :title="openDialogType == 'general' ? '普通球囊扩张详情' : 'dcb详情'"
    v-show="isShow"
    @close="Close"
  >
    <div class="top-item-row">
      <!-- <div class="item-row">
        <span>干预部位</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/PB_intervention_site"
          v-model="DCBInfo.interventionSite"
        ></cm-select>
      </div> -->
      <div class="item-row">
        <span>球囊直径</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="DCBInfo.balloonDiameter"
          size="mini"
        >
          <template slot="append">mm</template>
        </el-input>
      </div>
      <div class="item-row">
        <span>球囊直径球囊数量</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="DCBInfo.balloonNumber"
          size="mini"
        >
          <template slot="append"></template>
        </el-input>
      </div>
      <div class="item-row">
        <span>干预程度</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/PB_degree_of_intervention"
          v-model="DCBInfo.degreeIntervention"
        ></cm-select>
      </div>
    </div>
    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import CmSelect from '../../../../components/CmSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'DCBDetailDialog',
  components: {
    CmDialogBorder,
    CmSelect
  },
  props: {
    peripheralVesselsId: {
      type: String,
      require: true
    },
    openDialogType: {
      type: String
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      DCBInfo: {
        peripheralVesselsId: null, //外周血管主键
        interventionSite: null, //干预部位
        balloonDiameter: null, //球囊直径
        balloonNumber: null, //球囊数量
        degreeIntervention: null //干预程度
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    Show(bloodVessel) {
      this.DCBInfo = {}
      if (bloodVessel) this.DCBInfo = deepClone(bloodVessel)
      this.isShow = true
    },
    Save() {
      this.$emit('save', this.DCBInfo)
      this.Close()
    },

    Close() {
      this.isShow = false
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
