<style scoped lang="less">
.info-table {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);

  .inner-title {
    position: absolute;
    top: -12px;
    left: 12px;
    background: #fff;
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
  }

  .inner-btn {
    padding-left: 10px;
  }

  button {
    margin-right: 0.5rem;
  }

  /deep/.top-item-row .item-row span {
    margin: 0 0 0 5px;
  }
}

.inner-table {
  margin-top: 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
  left: 5px;
}

.inner-tabs {
  margin: 10px 10px;
  height: 250px;
}

.item-inline {
  margin: 15px 25px;
  display: flex;
  justify-content: flex-end;
}

.item-inline > span {
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
}

.item-column {
  display: flex;
  flex-direction: column;
}

.button-panel {
  width: 100%;
  bottom: 0;
  margin: 10px 0 0px 0;
  display: flex;
  justify-content: space-around;
}

.icon-finished::before {
  content: url('../../../../assets/img/finished.png');
}

.icon-warn::before {
  content: url('../../../../assets/img/warn.png');
}

.surgery-information {
  margin-top: 20px;
  margin-bottom: 20px;

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .input-form-item-row {
    width: 100%;
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 28%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;

    .input_has_unit {
      width: 200px;
      margin: 0;
    }
    .input_item {
      width: 200px;
      margin: 0;
    }
  }

  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 45%;
      margin: 0.4rem 1rem;
    }
  }

  .input-form-item-label {
    text-align: center;
    width: 120px;
    margin-right: 10px;
  }
}
.info-table-tab {
  // border-style: solid;
  // border-width: medium;
  // border-color: #ebeef5;
  display: flex;
  width: 99%;
  .info-table-left {
    width: 100%;
  }
  .info-table-right {
    margin-top: 10px;
    width: 100%;

    .input-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .input-form-item-row {
      width: 100%;
      margin: 0 0.5rem;
      display: flex;
      justify-content: flex-start;
    }

    .input-form-item {
      width: 50% !important;
      margin: 0.4rem 0rem;
      display: flex;
      align-items: center;
      .input_has_unit {
        margin: 0;
      }
    }
    @media screen and (max-width: 1180px) {
      .input-form-item {
        min-width: 40%;
      }
    }

    .input-form-item-label {
      width: 120px;
    }
  }
  .header {
    display: flex;
    padding-top: 5px;
    .title-left {
      width: 40%;
      text-align: center;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-right {
      width: 56%;
      text-align: center;
      min-height: 30px;
      line-height: 30px;
      padding-bottom: 5px;
      .header-button {
        width: 100px;
      }
    }
  }
}
</style>
<template>
  <div class="info-table">
    <span class="inner-title">病变部位</span>
    <div class="inner-btn">
      <el-button
        size="mini"
        type="primary"
        class="commonBtn"
        @click="_insert"
        v-if="!isReadonly"
        :disabled="isReadonly"
      >
        新增
      </el-button>
      <el-button
        size="mini"
        type="warning"
        class="commonBtn"
        @click="_modify"
        v-if="!isReadonly"
        :disabled="isReadonly"
      >
        修改
      </el-button>
      <el-button
        size="mini"
        type="danger"
        class="commonBtn"
        @click="open_delete"
        v-if="!isReadonly"
        :disabled="isReadonly"
      >
        删除
      </el-button>
    </div>
    <el-table
      class="inner-table"
      :data="tableData"
      highlight-current-row
      :header-cell-style="{ padding: 0 }"
      @row-click="_rowClick"
      @row-dblclick="_doubleModify"
      height="200px"
      style="width: 99%"
      border
    >
      <el-table-column prop="lesionSite" label="病变部位" align="center"></el-table-column>
      <el-table-column
        prop="lesionSiteDirection"
        label="病变部位方向"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pathologicalFeatures"
        label="病变特点"
        align="center"
      ></el-table-column>
      <el-table-column prop="iSRBlocking" label="ISR阻塞分级" align="center"></el-table-column>
      <el-table-column
        prop="bracketBroken"
        label="支架是否发生折断"
        align="center"
      ></el-table-column>
      <el-table-column prop="breakType" label="折断类型" align="center"></el-table-column>
      <el-table-column
        prop="degreeOfCalcification"
        label="钙化程度"
        align="center"
      ></el-table-column>
      <el-table-column prop="operationPosition" label="手术部位" align="center"></el-table-column>
      <el-table-column
        prop="handlingSuggestions"
        label="本次处理意见"
        align="center"
      ></el-table-column>
    </el-table>

    <div v-show="selectedBloodVessel" class="surgery-information">
      <el-tabs v-model="tabs_index" @tab-click="handleClick" type="border-card">
        <el-tab-pane
          v-if="
            selectedBloodVessel &&
            selectedBloodVessel.handlingSuggestions &&
            selectedBloodVessel.handlingSuggestions?.includes('血栓清除')
          "
          :key="key_list[0]"
        >
          <span slot="label">
            <i
              :class="selectedBloodVessel?.detailIndex != null ? 'icon-finished' : 'icon-warn'"
            ></i>
            血栓清除
          </span>
          <div class="radiography-table-left">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 100px">处理方式</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/PB_treatment_method"
                  :value.sync="thrombusInfo.treatmentMethod"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 100px">CDT中UK剂量</div>
                <cqt-input
                  :isReadonly="isReadonly"
                  class="input_item"
                  placeholder="请输入"
                  type="text"
                  size="mini"
                  unit="U"
                  v-model="thrombusInfo.cDTUKDose"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 100px">CDT累计时间</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_item"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    unit="min"
                    v-model="thrombusInfo.cumulativeTime"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 100px">是否同期进行了PTA</div>
                <cqt-radio
                  class="input_item"
                  :value.sync="thrombusInfo.pta"
                  :isReadonly="isReadonly"
                  style="margin-left: 15px; margin-top: 10px"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 100px">球囊直径</div>
                <cqt-input
                  :isReadonly="isReadonly"
                  class="input_item"
                  placeholder="请输入"
                  type="text"
                  size="mini"
                  unit="mm"
                  v-model="thrombusInfo.balloonDiameter"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 100px">溶栓导管类型</div>
                <cqt-select
                  class="input_item"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/PB_type_of_thrombolytic_catheter"
                  :value.sync="thrombusInfo.conduitType"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 100px">PTM装置</div>
                <cqt-select
                  class="input_item"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/PB_PTM"
                  :value.sync="thrombusInfo.pTMDevice"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 100px">是否采用喷药模式</div>
                <cqt-radio
                  class="input_item"
                  :value.sync="thrombusInfo.spraying"
                  :isReadonly="isReadonly"
                >
                  <el-radio label="是">是</el-radio>
                  <el-radio label="否">否</el-radio>
                </cqt-radio>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 100px">PTM中UK剂量</div>
                <cqt-input
                  :isReadonly="isReadonly"
                  class="input_item"
                  placeholder="请输入"
                  type="text"
                  size="mini"
                  unit="U"
                  v-model="thrombusInfo.pTMUKDose"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 100px">
                  <span>血栓清除率</span>
                  <div
                    @mouseenter="thrombusClearanceIsExitHover = true"
                    @mouseleave="thrombusClearanceIsExitHover = false"
                    class="menus"
                    style="float: right"
                  >
                    <i class="el-icon-warning" v-show="!thrombusClearanceIsExitHover"></i>
                    <i
                      v-show="thrombusClearanceIsExitHover"
                      class="el-icon-warning"
                      slot="reference"
                    />
                    <el-popover
                      placement="bottom"
                      width="420"
                      trigger="manual"
                      v-model="thrombusClearanceIsExitHover"
                    >
                      <img :src="thrombusClearanceExitActive" style="width: 420px; height: 220px" />
                    </el-popover>
                  </div>
                </div>
                <cqt-select
                  class="input_item"
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/PB_thrombus_clearance_rate"
                  :value.sync="thrombusInfo.thrombusClearance"
                />
              </div>
            </div>
          </div>
          <div class="button-panel"></div>
        </el-tab-pane>

        <el-tab-pane
          v-if="
            selectedBloodVessel &&
            selectedBloodVessel.handlingSuggestions &&
            selectedBloodVessel.handlingSuggestions?.includes('普通球囊扩张')
          "
          :key="key_list[1]"
        >
          <span slot="label">
            <i
              :class="selectedBloodVessel?.detailIndex != null ? 'icon-finished' : 'icon-warn'"
            ></i>
            普通球囊扩张
          </span>

          <div class="info-table-tab">
            <div class="info-table-left">
              <div class="header">
                <div class="title-left">
                  <span class="title">普通球囊扩张详情</span>
                </div>
                <div class="title-right" v-show="!isReadonly">
                  <el-button
                    type="primary"
                    size="mini"
                    class="header-button"
                    @click="generalInsert"
                    :disabled="isReadonly"
                  >
                    添加
                  </el-button>
                  <el-button
                    type="danger"
                    size="mini"
                    class="header-button"
                    @click="generalDelete"
                    :disabled="isReadonly"
                  >
                    删除
                  </el-button>
                </div>
              </div>
              <el-table
                :data="generalTableDetail"
                class="inner-table"
                highlight-current-row
                :header-cell-style="{ padding: 0 }"
                @row-click="_rowOtherClick"
                :key="tableKey"
                height="200px"
                style="width: 96%"
                border
                :cell-style="{ 'text-align': 'center' }"
              >
                <el-table-column prop="balloonDiameter" label="球囊直径"></el-table-column>
                <el-table-column prop="balloonNumber" label="球囊数量"></el-table-column>
                <el-table-column prop="degreeIntervention" label="干预程度"></el-table-column>
              </el-table>
            </div>
            <div class="info-table-right">
              <div class="input-form">
                <div class="input-form-item">
                  <div class="input-form-item-label" style="width: 140px">
                    <span>手术是否成功</span>
                    <div
                      @mouseenter="balloonSurgeryIsExitHover = true"
                      @mouseleave="balloonSurgeryIsExitHover = false"
                      class="menus"
                      style="float: right"
                    >
                      <i class="el-icon-warning" v-show="!balloonSurgeryIsExitHover"></i>
                      <i
                        v-show="balloonSurgeryIsExitHover"
                        class="el-icon-warning"
                        slot="reference"
                      />
                      <el-popover
                        placement="bottom"
                        width="370"
                        trigger="manual"
                        v-model="balloonSurgeryIsExitHover"
                      >
                        <img :src="balloonSurgeryExitActive" style="width: 370px; height: 130px" />
                      </el-popover>
                    </div>
                  </div>
                  <cqt-radio
                    style="margin-left: 15px"
                    :value.sync="balloonDilatationDetail.balloonSurgery"
                    :isReadonly="isReadonly"
                  >
                    <el-radio label="是">是</el-radio>
                    <el-radio label="否">否</el-radio>
                  </cqt-radio>
                </div>
                <!-- <div class="input-form-item">
                  <div class="input-form-item-label" style="width: 142px">
                    <span>DCB扩张后夹层分型</span>
                    <div
                      @mouseenter="dcbsandwichTypingIsExitHover = true"
                      @mouseleave="dcbsandwichTypingIsExitHover = false"
                      class="menus"
                      style="float: right"
                    >
                      <i class="el-icon-warning" v-show="!dcbsandwichTypingIsExitHover"></i>
                      <i
                        v-show="dcbsandwichTypingIsExitHover"
                        class="el-icon-warning"
                        slot="reference"
                      />
                      <div style="position: absolute; top: -24px; right: 945px">
                        <el-popover
                          placement="bottom"
                          width="550"
                          trigger="manual"
                          v-model="dcbsandwichTypingIsExitHover"
                        >
                          <img
                            :src="dcbsandwichTypingExitActive"
                            style="width: 550px; height: 320px"
                          />
                        </el-popover>
                      </div>
                    </div>
                  </div>
                  <div style="margin-left: 15px">
                    <cqt-input
                      :isReadonly="isReadonly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="text"
                      size="mini"
                      v-model="dcbDetail.dCBSandwichTyping"
                    />
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="button-panel"></div>
        </el-tab-pane>

        <el-tab-pane
          v-if="
            selectedBloodVessel &&
            selectedBloodVessel.handlingSuggestions &&
            selectedBloodVessel.handlingSuggestions?.includes('DCB')
          "
          :key="key_list[2]"
        >
          <span slot="label">
            <i
              :class="selectedBloodVessel?.detailIndex != null ? 'icon-finished' : 'icon-warn'"
            ></i>
            DCB
          </span>

          <div class="info-table-tab">
            <div class="info-table-left">
              <div class="header">
                <div class="title-left">
                  <span class="title">DCB详情</span>
                </div>
                <div class="title-right" v-show="!isReadonly">
                  <el-button
                    type="primary"
                    size="mini"
                    class="header-button"
                    @click="DCBInsert"
                    :disabled="isReadonly"
                  >
                    添加
                  </el-button>
                  <el-button
                    type="danger"
                    size="mini"
                    class="header-button"
                    @click="DCBDelete"
                    :disabled="isReadonly"
                  >
                    删除
                  </el-button>
                </div>
              </div>
              <el-table
                :data="dcbTableDetail"
                class="inner-table"
                highlight-current-row
                :header-cell-style="{ padding: 0 }"
                @row-click="_rowOtherClick"
                :key="tableKey"
                height="200px"
                style="width: 96%"
                border
                :cell-style="{ 'text-align': 'center' }"
              >
                <el-table-column prop="balloonDiameter" label="球囊直径"></el-table-column>
                <el-table-column prop="balloonNumber" label="球囊数量"></el-table-column>
                <el-table-column prop="degreeIntervention" label="干预程度"></el-table-column>
              </el-table>
            </div>
            <div class="info-table-right">
              <div class="input-form">
                <div class="input-form-item">
                  <div class="input-form-item-label" style="width: 140px">
                    <span>手术是否成功</span>
                    <div
                      @mouseenter="dcbsurgeryIsExitHover = true"
                      @mouseleave="dcbsurgeryIsExitHover = false"
                      class="menus"
                      style="float: right"
                    >
                      <i class="el-icon-warning" v-show="!dcbsurgeryIsExitHover"></i>
                      <i v-show="dcbsurgeryIsExitHover" class="el-icon-warning" slot="reference" />
                      <el-popover
                        placement="bottom"
                        width="400"
                        trigger="manual"
                        v-model="dcbsurgeryIsExitHover"
                      >
                        <img :src="dcbsurgeryExitActive" style="width: 400px; height: 100px" />
                      </el-popover>
                    </div>
                  </div>
                  <cqt-radio
                    style="margin-left: 15px; width: 188px"
                    :value.sync="dcbDetail.dCBSurgery"
                    :isReadonly="isReadonly"
                  >
                    <el-radio label="是">是</el-radio>
                    <el-radio label="否">否</el-radio>
                  </cqt-radio>
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label" style="width: 142px">
                    <span>DCB扩张后夹层分型</span>
                    <div
                      @mouseenter="dcbsandwichTypingIsExitHover = true"
                      @mouseleave="dcbsandwichTypingIsExitHover = false"
                      class="menus"
                      style="float: right"
                    >
                      <i class="el-icon-warning" v-show="!dcbsandwichTypingIsExitHover"></i>
                      <i
                        v-show="dcbsandwichTypingIsExitHover"
                        class="el-icon-warning"
                        slot="reference"
                      />
                      <div style="position: absolute; top: -24px; right: 945px">
                        <el-popover
                          placement="bottom"
                          width="550"
                          trigger="manual"
                          v-model="dcbsandwichTypingIsExitHover"
                        >
                          <img
                            :src="dcbsandwichTypingExitActive"
                            style="width: 550px; height: 320px"
                          />
                        </el-popover>
                      </div>
                    </div>
                  </div>
                  <div style="margin-left: 15px">
                    <cqt-input
                      :isReadonly="isReadonly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="text"
                      size="mini"
                      v-model="dcbDetail.dCBSandwichTyping"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="button-panel"></div>
        </el-tab-pane>

        <el-tab-pane
          v-if="
            selectedBloodVessel &&
            selectedBloodVessel.handlingSuggestions &&
            selectedBloodVessel.handlingSuggestions?.includes('支架植入')
          "
          :key="key_list[3]"
        >
          <span slot="label">
            <i
              :class="selectedBloodVessel?.detailIndex != null ? 'icon-finished' : 'icon-warn'"
            ></i>
            支架植入
          </span>

          <div class="info-table-tab">
            <div class="info-table-left">
              <div class="header">
                <div class="title-left">
                  <span>支架详情</span>
                </div>
                <div class="title-right" v-show="!isReadonly">
                  <el-button
                    type="primary"
                    size="mini"
                    class="header-button"
                    @click="stentImplantationInsert"
                    :disabled="isReadonly"
                  >
                    添加
                  </el-button>
                  <el-button
                    type="danger"
                    size="mini"
                    class="header-button"
                    @click="stentImplantationDelete"
                    :disabled="isReadonly"
                  >
                    删除
                  </el-button>
                </div>
              </div>
              <el-table
                :data="stentImplantationTableDetail"
                class="inner-table"
                highlight-current-row
                :header-cell-style="{ padding: 0 }"
                @row-click="_rowOtherClick"
                :key="tableKey"
                height="200px"
                style="width: 96%"
                border
                :cell-style="{ 'text-align': 'center' }"
              >
                <el-table-column prop="supportType" label="支架类型"></el-table-column>
                <el-table-column prop="supportDiameter" label="支架直径"></el-table-column>
                <el-table-column prop="supportNumber" label="支架数量"></el-table-column>
              </el-table>
              <stent-implantation-dialog
                ref="stentImplantationDialog"
                :peripheralVesselsId="peripheralVesselsId"
                @save="stentImplantation_dialogSave"
              ></stent-implantation-dialog>
            </div>
            <div class="info-table-right">
              <div class="input-form">
                <div class="input-form-item">
                  <div class="input-form-item-label" style="width: 142px">
                    <span>手术是否成功</span>
                    <div
                      @mouseenter="supportSurgeryIsExitHover = true"
                      @mouseleave="supportSurgeryIsExitHover = false"
                      class="menus"
                      style="float: right"
                    >
                      <i class="el-icon-warning" v-show="!supportSurgeryIsExitHover"></i>
                      <i
                        v-show="supportSurgeryIsExitHover"
                        class="el-icon-warning"
                        slot="reference"
                      />
                      <el-popover
                        placement="bottom"
                        width="400"
                        trigger="manual"
                        v-model="supportSurgeryIsExitHover"
                      >
                        <img :src="supportSurgeryExitActive" style="width: 400px; height: 100px" />
                      </el-popover>
                    </div>
                  </div>
                  <cqt-radio
                    style="margin-left: 15px"
                    :value.sync="stentImplantationDetail.supportSurgery"
                    :isReadonly="isReadonly"
                  >
                    <el-radio label="是">是</el-radio>
                    <el-radio label="否">否</el-radio>
                  </cqt-radio>
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label" style="width: 142px">支架植入情况</div>
                  <cqt-select
                    :isReadonly="isReadonly"
                    style="margin-left: 15px"
                    activeUrl="/v1/webconsole/comboData/get/PB_stent_implantation"
                    :value.sync="stentImplantationDetail.supportImplantation"
                  />
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label" style="width: 142px">支架总长度</div>
                  <div style="margin-left: 15px">
                    <cqt-input
                      :isReadonly="isReadonly"
                      class="input_has_unit"
                      placeholder="请输入"
                      type="text"
                      size="mini"
                      unit="mm"
                      v-model="stentImplantationDetail.supportLength"
                    />
                  </div>
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label" style="width: 142px">支架位置</div>
                  <cqt-select
                    :isReadonly="isReadonly"
                    style="margin-left: 15px"
                    activeUrl="/v1/webconsole/comboData/get/PB_bracket_position"
                    :value.sync="stentImplantationDetail.supportPosition"
                  />
                </div>
                <div class="input-form-item">
                  <div class="input-form-item-label" style="width: 142px">支架是否跨膝</div>
                  <cqt-radio
                    style="margin-left: 15px"
                    :value.sync="stentImplantationDetail.crossKnee"
                    :isReadonly="isReadonly"
                  >
                    <el-radio label="是">是</el-radio>
                    <el-radio label="否">否</el-radio>
                  </cqt-radio>
                </div>
              </div>
            </div>
          </div>
          <div class="button-panel"></div>
        </el-tab-pane>

        <el-tab-pane
          v-if="
            selectedBloodVessel &&
            selectedBloodVessel.handlingSuggestions &&
            selectedBloodVessel.handlingSuggestions?.includes('斑块减容（DA）')
          "
          :key="key_list[4]"
        >
          <span slot="label">
            <i
              :class="selectedBloodVessel?.detailIndex != null ? 'icon-finished' : 'icon-warn'"
            ></i>
            斑块减容（DA）
          </span>

          <div class="input-form">
            <div class="input-form-item">
              <div class="input-form-item-label" style="width: 140px">减容方式</div>
              <cqt-select
                class="input_item"
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/PB_capacity_reduction_mode"
                :value.sync="daDetail.volumeReduction"
              />
            </div>
            <div class="input-form-item">
              <div class="input-form-item-label" style="width: 142px">
                <span>手术是否成功</span>
                <div
                  @mouseenter="dasurgeryIsExitHover = true"
                  @mouseleave="dasurgeryIsExitHover = false"
                  class="menus"
                  style="float: right"
                >
                  <i class="el-icon-warning" v-show="!dasurgeryIsExitHover"></i>
                  <i v-show="dasurgeryIsExitHover" class="el-icon-warning" slot="reference" />
                  <el-popover
                    placement="bottom"
                    width="400"
                    trigger="manual"
                    v-model="dasurgeryIsExitHover"
                  >
                    <img :src="dasurgeryExitActive" style="width: 400px; height: 100px" />
                  </el-popover>
                </div>
              </div>
              <cqt-radio
                class="input_item"
                :value.sync="daDetail.dASurgery"
                :isReadonly="isReadonly"
              >
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </cqt-radio>
            </div>
            <div class="input-form-item">
              <div class="input-form-item-label" style="width: 142px">是否使用保护伞</div>
              <cqt-radio
                class="input_item"
                :value.sync="daDetail.useUmbrella"
                :isReadonly="isReadonly"
              >
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </cqt-radio>
            </div>
            <div class="input-form-item">
              <div class="input-form-item-label" style="width: 142px">保护伞类型</div>
              <cqt-select
                class="input_item"
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/PB_umbrella_type"
                :value.sync="daDetail.umbrellaType"
              />
            </div>
            <div class="input-form-item">
              <div class="input-form-item-label" style="width: 142px">保护伞型号</div>
              <cqt-input
                class="input_has_unit"
                :isReadonly="isReadonly"
                placeholder="请输入"
                type="text"
                size="mini"
                unit="F"
                v-model="daDetail.umbrellaModel"
              />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <d-c-b-detail-dialog
      ref="dcbDetailDialog"
      :openDialogType="openDialogType"
      :peripheralVesselsId="peripheralVesselsId"
      @save="DCB_dialogSave"
    ></d-c-b-detail-dialog>

    <CoronaryInfoInputDialog
      v-bind="$attrs"
      :formId="formId"
      :isReadonly="isReadonly"
      ref="coronaryInfoInputDialog"
      @save="_dialogSave"
    ></CoronaryInfoInputDialog>
  </div>
</template>

<script>
import CoronaryInfoInputDialog from './CoronaryInfoInputDialog.vue'
import DCBDetailDialog from './DCBDetailDialog'
import StentImplantationDialog from './StentImplantationDialog.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'

export default {
  name: 'CoronaryInfoInput',
  components: {
    CoronaryInfoInputDialog,
    CqtInput,
    CqtSelect,
    CqtRadio,

    DCBDetailDialog,
    StentImplantationDialog
  },
  model: {
    event: 'change',
    prop: 'allDetail'
  },
  props: {
    isReadonly: {
      type: Boolean,
      require: true
    },
    formId: {
      type: String,
      require: true
    },
    allDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      equipment_list: [],
      showList: [],
      selectedROTA: null,
      selectedBloodVessel: null,
      tableKey: null,
      tableData: [],
      equipmentInfo: [],
      key_list: [0, 1, 2, 3, 4, 5, 6],
      tabs_index: '0',
      // PCI接口
      coronaryInterveneDetail: {
        interventionSuccess: null,
        intraoperativeComplication: null,
        note: null,
        operationMethod: null,
        postDegreeValue1: 0,
        postDegreeValue2: 0,
        postTIMI: null,
        stentingAmount: null,
        valueType: null
      },
      // IVUS
      coronaryIVUSDetail: {
        postEEMArea: 0,
        postIVUS: null,
        postMaxEEMDiameter: 0,
        postMaxLumenDiameter: 0,
        postMinEEMDiameter: 0,
        postMinLumenArea: 0,
        postMinLumenDiameter: 0,
        postPlaqueLoad: 0,
        preEEMArea: 0,
        preIVUS: null,
        preMaxEEMDiameter: 0,
        preMaxLumenDiameter: 0,
        preMinEEMDiameter: 0,
        preMinLumenArea: 0,
        preMinLumenDiameter: 0,
        prePlaqueLoad: 0
      },

      // FFR
      coronaryFFRDetail: {
        postFFR: 0,
        postJump: null,
        preFFR: 0,
        preJump: null
      },
      // OCT
      coronaryOCTDetail: {
        postEEMArea: 0,
        postMaxEEMDiameter: 0,
        postMaxLumenDiameter: 0,
        postMinEEMDiameter: 0,
        postMinLumenArea: 0,
        postMinLumenDiameter: 0,
        postOCT: null,
        postPlaqueLoad: 0,
        preEEMArea: 0,
        preMaxEEMDiameter: 0,
        preMaxLumenDiameter: 0,
        preMinEEMDiameter: 0,
        preMinLumenArea: 0,
        preMinLumenDiameter: 0,
        preOCT: null,
        prePlaqueLoad: 0
      },
      // 旋磨
      coronaryROTADetail: [],
      coronaryROTADetailInfo: {
        immediateEffect: null,
        revolution: 0,
        rotaSize: null
      },

      // 抽栓
      coronaryThrombus: {
        thrombusCount: null,
        thrombusNumber: null,
        thrombusTime: 0,
        thrombusType: null
      },

      /*----------------------------------------*/
      isrblockingExitActive: require('@/assets/img/nerve/ISRzuse.png'),
      breakTypeExitActive: require('@/assets/img/nerve/break.png'),
      calcificationExitActive: require('@/assets/img/nerve/calcification.png'),
      ISRIsExitHover: false, //是否悬浮到图标上
      breakIsExitHover: false, //是否悬浮到图标上
      CalcificationIsExitHover: false, //是否悬浮到图标上
      balloonSurgeryExitActive: require('@/assets/img/nerve/balloonSurgery.png'),
      balloonSurgeryIsExitHover: false, //是否悬浮到图标上
      balloonVascularExitActive: require('@/assets/img/nerve/vascularScore.png'),
      balloonVascularIsExitHover: false, //是否悬浮到图标上
      balloonImprovementExitActive: require('@/assets/img/nerve/balloonImprovement.png'),
      balloonImprovementIsExitHover: false, //是否悬浮到图标上
      dcbsurgeryExitActive: require('@/assets/img/nerve/balloonSurgery.png'),
      dcbsurgeryIsExitHover: false, //是否悬浮到图标上
      dcbvascularExitActive: require('@/assets/img/nerve/vascularScore.png'),
      dcbvascularIsExitHover: false, //是否悬浮到图标上
      dcbimprovementExitActive: require('@/assets/img/nerve/balloonImprovement.png'),
      dcbimprovementIsExitHover: false, //是否悬浮到图标上
      dcbsandwichTypingExitActive: require('@/assets/img/nerve/dcbsandwichTyping.png'),
      dcbsandwichTypingIsExitHover: false, //是否悬浮到图标上
      supportSurgeryExitActive: require('@/assets/img/nerve/balloonSurgery.png'),
      supportSurgeryIsExitHover: false, //是否悬浮到图标上
      supportImprovementExitActive: require('@/assets/img/nerve/balloonImprovement.png'),
      supportImprovementIsExitHover: false, //是否悬浮到图标上
      daimprovementExitActive: require('@/assets/img/nerve/balloonImprovement.png'),
      daimprovementIsExitHover: false, //是否悬浮到图标上
      dasurgeryExitActive: require('@/assets/img/nerve/balloonSurgery.png'),
      dasurgeryIsExitHover: false, //是否悬浮到图标上
      supportVascularExitActive: require('@/assets/img/nerve/vascularScore.png'),
      supportVascularIsExitHover: false, //是否悬浮到图标上
      daVascularExitActive: require('@/assets/img/nerve/vascularScore.png'),
      daVascularIsExitHover: false, //是否悬浮到图标上
      thrombusClearanceExitActive: require('@/assets/img/nerve/thrombusClearance.png'),
      thrombusClearanceIsExitHover: false, //是否悬浮到图标上
      thrombusInfo: {
        id: null, //主键
        peripheralVesselsId: null, //外周血管主键
        treatmentMethod: null, //处理方式
        cDTUKDose: null, //CDT中UK剂量
        cumulativeTime: null, //CDT累计时间
        pta: null, //是否同期进行了PTA
        balloonDiameter: null, //球囊直径
        conduitType: null, //溶栓导管类型
        pTMDevice: null, //PTM装置
        spraying: null, //是否采用喷药模式
        pTMUKDose: null, //PTM中UK剂量
        thrombusClearance: null //血栓清除率
      },
      //普通球囊扩张
      balloonDilatationDetail: {
        operativeStyle: null, //术式
        balloonSurgery: null, //手术是否成功
        balloonRunOff: null, //术后膝下Run-off评分
        balloonVascular: null, //术后踝下血管评分
        balloonABI: null, //术后ABI
        balloonImprovement: null, //症状改善情况
        numberBalloons: null, //普通球囊数量
        balloonMinorComplications: null, //围手术期中小并发症
        balloonSeriousCom: null //围手术期严重并发症
      },
      //DCB
      dcbDetail: {
        dCBSurgery: null, //手术是否成功
        dCBRunOff: null, //术后膝下Run-off评分
        dCBVascular: null, //术后踝下血管评分
        dcbabi: null, //术后ABI
        dCBImprovement: null, //症状改善情况
        dCBMinorComplications: null, //围手术期中小并发症
        dCBSeriousCom: null, //围手术期严重并发症
        dCBSandwichTyping: null //DCB扩张后夹层分型
      },
      stentImplantationDetail: {
        supportImplantation: null, //支架植入情况
        supportLength: null, //支架总长度
        supportPosition: null, //支架位置
        crossKnee: null, //支架是否跨膝
        supportSurgery: null, //手术是否成功
        supportRunOff: null, //术后膝下Run-off评分
        supportVascular: null, //术后踝下血管评分
        supportABI: null, //术后ABI
        supportImprovement: null, //症状改善情况
        supportMinorComplications: null, //围手术期中小并发症
        supportSeriousCom: null //围手术期严重并发症
      },
      daDetail: {
        volumeReduction: null, //减容方式
        dASurgery: null, //手术是否成功
        dARunOff: null, //术后膝下Run-off评分
        dAVascular: null, //术后踝下血管评分
        daabi: null, //术后ABI
        dAImprovement: null, //症状改善情况
        useUmbrella: null, //是否使用保护伞
        umbrellaType: null, //保护伞类型
        umbrellaModel: null, //保护伞型号
        dAMinorComplications: null, //围手术期中小并发症
        dASeriousCom: null //围手术期严重并发症
      },
      dcbTableDetail: [],
      peripheralVesselsId: '-1',
      selectedRow: null,
      stentImplantationTableDetail: [],
      generalTableDetail: [],
      openDialogType: undefined // 普通、dcb
    }
  },
  computed: {},
  watch: {
    selectedBloodVessel: {
      handler(val) {
        if (val) {
          this.getAllSuggestions(val)
        }
      }
    },
    allDetail: {
      handler(val) {
        if (val) {
          this._getAllBloodVesselDetail()
        }
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 页码切换
    handleClick(tab) {
      console.log(11, tab)
      this.tabs_index = tab.index
    },
    _getAllBloodVesselDetail() {
      console.log(this.allDetail)
      let url = `/v1/webconsole/eform/extendfields/${this.formId}/${this.allDetail.peripheralVesselsInformation.id}`
      let formData = new FormData()
      formData.append('elementName', 'peripheralVesselsInformation')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            console.log('tableData', res.data)
            this.tableData = res.data.map((item) => ({ ...item, detailIndex: item.id }))
            for (let i = 0; i < this.tableData.length; i++) {
              const element = this.tableData[i]
              if (element.handlingSuggestions) {
                this.getAllSuggestions(element, 'init')
              }
            }
          }
        },
        () => {}
      )
    },
    getAllSuggestions(val, type) {
      if (val.handlingSuggestions?.includes('血栓清除')) {
        this._getThrombectomy(val, type)
      }
      if (val.handlingSuggestions?.includes('普通球囊扩张')) {
        this._getGeneralBalloon(val, type)
      }
      if (val.handlingSuggestions?.includes('DCB')) {
        this._getDCBBalloon(val, type)
      }
      if (val.handlingSuggestions?.includes('支架植入')) {
        this._getStentImplantation(val, type)
      }
      if (val.handlingSuggestions?.includes('斑块减容（DA）')) {
        this._getDA(val, type)
      }
    },

    // 血栓清除
    _getThrombectomy(val, type) {
      if (type === 'init') {
        this.thrombusInfo = {
          ...this.$options.data().thrombusInfo
        }
        val.peripheralVesselsThrombus = this.thrombusInfo
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'peripheralVesselsThrombus')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.peripheralVesselsThrombus = res.data.data[0]
                this.thrombusInfo = val.peripheralVesselsThrombus
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.peripheralVesselsThrombus) {
          this.thrombusInfo = val.peripheralVesselsThrombus
        } else {
          this.thrombusInfo = {
            ...this.$options.data().thrombusInfo
          }
          val.peripheralVesselsThrombus = this.thrombusInfo
        }
      }
    },
    // 普通球囊扩张
    _getGeneralBalloon(val, type) {
      if (type === 'init') {
        this.balloonDilatationDetail = {
          ...this.$options.data().balloonDilatationDetail
        }
        val.peripheralVesselsBalloon = this.balloonDilatationDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'peripheralVesselsBalloon')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.peripheralVesselsBalloon = res.data.data[0]
                this.balloonDilatationDetail = val.peripheralVesselsBalloon
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.peripheralVesselsBalloon) {
          this.balloonDilatationDetail = val.peripheralVesselsBalloon
        } else {
          this.balloonDilatationDetail = {
            ...this.$options.data().balloonDilatationDetail
          }
          val.peripheralVesselsBalloon = this.balloonDilatationDetail
        }
      }
      // 表格
      if (type === 'init') {
        this.generalTableDetail = []
        val.peripheralVesselsBalloonDetail = this.generalTableDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'peripheralVesselsBalloonDetail')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.peripheralVesselsBalloonDetail = res.data.data
                this.generalTableDetail = val.peripheralVesselsBalloonDetail
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.peripheralVesselsBalloonDetail) {
          this.generalTableDetail = val.peripheralVesselsBalloonDetail
        } else {
          this.generalTableDetail = this.$options.data().generalTableDetail
          val.peripheralVesselsBalloonDetail = this.generalTableDetail
        }
      }
    },
    // DCB
    _getDCBBalloon(val, type) {
      if (type === 'init') {
        this.dcbDetail = {
          ...this.$options.data().dcbDetail
        }
        val.peripheralVesselsDCB = this.dcbDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'peripheralVesselsDCB')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.peripheralVesselsDCB = res.data.data[0]
                this.dcbDetail = val.peripheralVesselsDCB
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.peripheralVesselsDCB) {
          this.dcbDetail = val.peripheralVesselsDCB
        } else {
          this.dcbDetail = {
            ...this.$options.data().dcbDetail
          }
          val.peripheralVesselsDCB = this.dcbDetail
        }
      }
      // 表格
      if (type === 'init') {
        this.dcbTableDetail = []
        val.peripheralVesselsDCBDetails = this.dcbTableDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'peripheralVesselsDCBDetails')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.peripheralVesselsDCBDetails = res.data.data
                this.dcbTableDetail = val.peripheralVesselsDCBDetails
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.peripheralVesselsDCBDetails) {
          this.dcbTableDetail = val.peripheralVesselsDCBDetails
        } else {
          this.dcbTableDetail = this.$options.data().dcbTableDetail
          val.peripheralVesselsDCBDetails = this.dcbTableDetail
        }
      }
    },
    // 支架植入
    _getStentImplantation(val, type) {
      if (type === 'init') {
        this.stentImplantationDetail = {
          ...this.$options.data().stentImplantationDetail
        }
        val.peripheralVesselsImplantation = this.stentImplantationDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'peripheralVesselsImplantation')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.peripheralVesselsImplantation = res.data.data[0]
                this.stentImplantationDetail = val.peripheralVesselsImplantation
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.peripheralVesselsImplantation) {
          this.stentImplantationDetail = val.peripheralVesselsImplantation
        } else {
          this.stentImplantationDetail = {
            ...this.$options.data().stentImplantationDetail
          }
          val.peripheralVesselsImplantation = this.stentImplantationDetail
        }
      }

      // 表格
      if (type === 'init') {
        this.stentImplantationTableDetail = []
        val.peripheralVesselsStentImplantation = this.stentImplantationTableDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'peripheralVesselsStentImplantation')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.peripheralVesselsStentImplantation = res.data.data
                this.stentImplantationTableDetail = val.peripheralVesselsStentImplantation
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.peripheralVesselsStentImplantation) {
          this.stentImplantationTableDetail = val.peripheralVesselsStentImplantation
        } else {
          this.stentImplantationTableDetail = this.$options.data().stentImplantationTableDetail
          val.peripheralVesselsStentImplantation = this.stentImplantationTableDetail
        }
      }
    },
    // DA
    _getDA(val, type) {
      if (type === 'init') {
        this.daDetail = {
          ...this.$options.data().daDetail
        }
        val.peripheralVesselsDA = this.daDetail
        const url = `/v1/webconsole/eform/extendfields/${this.formId}/${val.detailIndex}`
        let formData = new FormData()
        formData.append('elementName', 'peripheralVesselsDA')
        this.$api.post(url, formData).then(
          (res) => {
            if (res.data && res.data.data) {
              if (res.data.data.length) {
                val.peripheralVesselsDA = res.data.data[0]
                this.daDetail = val.peripheralVesselsDA
              }
            }
          },
          (err) => console.log(err)
        )
      } else {
        if (val.peripheralVesselsDA) {
          this.daDetail = val.peripheralVesselsDA
        } else {
          this.daDetail = {
            ...this.$options.data().daDetail
          }
          val.peripheralVesselsDA = this.daDetail
        }
      }
    },
    _insert() {
      this.$refs.coronaryInfoInputDialog.Show()
    },

    _modify() {
      if (!this.selectedBloodVessel) {
        return this.$message.warning('请先选择一条病变部位')
      }
      if (this.selectedBloodVessel) {
        this.$refs.coronaryInfoInputDialog.Show(this.selectedBloodVessel)
      }
    },
    _rowClick(row) {
      console.log(111111, row)
      this.selectedBloodVessel = row
      this.tabs_index = '0'
      if (row.detailIndex) {
        this.key_list = [
          row.detailIndex,
          row.detailIndex + Math.random(0, 1000),
          row.detailIndex + Math.random(0, 1000),
          row.detailIndex + Math.random(0, 1000),
          row.detailIndex + Math.random(0, 1000),
          row.detailIndex + Math.random(0, 1000),
          row.detailIndex + Math.random(0, 1000)
        ]
      } else {
        this.key_list = [
          row.ids,
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000),
          row.ids + Math.random(0, 1000)
        ]
      }
    },
    _rowROTAClick(row) {
      this.selectedROTA = row
      this.coronaryROTADetailInfo = { ...this.selectedROTA }
    },
    _doubleModify(oldBloodVessel) {
      if (this.isReadonly) {
        return this.$message({
          message: '报告已签名,无法进行编辑操作',
          type: 'warning'
        })
      }
      this.selectedBloodVessel = oldBloodVessel
      this.$refs.coronaryInfoInputDialog.Show(this.selectedBloodVessel)
    },
    _dialogSave(val) {
      this.selectedBloodVessel = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.tableData.length; i++) {
          const element = this.tableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.tableData[i] = val
            this.$set(this.tableData, i, this.tableData[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.tableData.push(val)
        }
      } else {
        for (let i = 0; i < this.tableData.length; i++) {
          const element = this.tableData[i]
          if (val.id === element.id) {
            this.tableData[i] = val
            this.$set(this.tableData, i, this.tableData[i])
            break
          }
        }
      }
    },
    open_delete() {
      if (!this.selectedBloodVessel) return this.$message.warning('请选择一支病变部位')
      this.$confirm('是否删除该病变部位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._delete()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _delete() {
      if (this.selectedBloodVessel) {
        if (this.selectedBloodVessel.detailIndex) {
          for (let i = 0; i < this.tableData.length; i++) {
            const element = this.tableData[i]
            if (this.selectedBloodVessel.detailIndex === element.detailIndex) {
              this.tableData.splice(i, 1)
              break
            }
          }
        } else {
          for (let i = 0; i < this.tableData.length; i++) {
            const element = this.tableData[i]
            if (this.selectedBloodVessel.ids === element.ids) {
              this.tableData.splice(i, 1)
              break
            }
          }
        }
        this.selectedBloodVessel = null
      }
    },

    /*------------------------------------------------------------------*/
    _rowOtherClick(row) {
      this.selectedRow = row
    },
    /*--------------------*/
    stentImplantationInsert() {
      if (this.isReadonly) return

      this.$refs.stentImplantationDialog.Show()
    },
    stentImplantationDelete() {
      if (this.isReadonly) return

      if (this.selectedRow) {
        if (this.selectedRow.id) {
          for (let i = 0; i < this.stentImplantationTableDetail.length; i++) {
            const element = this.stentImplantationTableDetail[i]
            if (this.selectedRow.id === element.id) {
              this.stentImplantationTableDetail.splice(i, 1)
              break
            }
          }
        } else {
          for (let i = 0; i < this.stentImplantationTableDetail.length; i++) {
            const element = this.stentImplantationTableDetail[i]
            if (this.selectedRow.ids === element.ids) {
              this.stentImplantationTableDetail.splice(i, 1)
              break
            }
          }
        }
      }
    },
    stentImplantation_dialogSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.stentImplantationTableDetail.length; i++) {
          const element = this.stentImplantationTableDetail[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.stentImplantationTableDetail[i] = val
            this.$set(this.stentImplantationTableDetail, i, this.stentImplantationTableDetail[i])
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.stentImplantationTableDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.stentImplantationTableDetail.length; i++) {
          const element = this.stentImplantationTableDetail[i]
          if (val.id === element.id) {
            this.stentImplantationTableDetail[i] = val
            this.$set(this.stentImplantationTableDetail, i, this.stentImplantationTableDetail[i])
          }
        }
      }
    },
    // 普通
    generalInsert() {
      if (this.isReadonly) return
      this.openDialogType = 'general'
      this.$refs.dcbDetailDialog.Show()
    },
    generalDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          for (let i = 0; i < this.generalTableDetail.length; i++) {
            const element = this.generalTableDetail[i]
            if (this.selectedRow.id === element.id) {
              this.generalTableDetail.splice(i, 1)
              break
            }
          }
        } else {
          const index = this.generalTableDetail.findIndex(
            (item) => item.ids === this.selectedRow.ids
          )
          if (index !== -1) {
            this.generalTableDetail.splice(index, 1)
          }
        }
      }
    },
    // dcb
    DCBInsert() {
      if (this.isReadonly) return
      this.openDialogType = 'dcb'
      this.$refs.dcbDetailDialog.Show()
    },
    DCBDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          for (let i = 0; i < this.dcbTableDetail.length; i++) {
            const element = this.dcbTableDetail[i]
            if (this.selectedRow.id === element.id) {
              this.dcbTableDetail.splice(i, 1)
              break
            }
          }
        } else {
          const index = this.dcbTableDetail.findIndex((item) => item.ids === this.selectedRow.ids)
          if (index !== -1) {
            this.dcbTableDetail.splice(index, 1)
          }
        }
      }
    },
    DCB_dialogSave(val) {
      if (this.openDialogType == 'dcb') {
        if (!val.id) {
          let isId = true
          for (let i = 0; i < this.dcbTableDetail.length; i++) {
            const element = this.dcbTableDetail[i]
            if (val.ids && val.ids === element.ids) {
              isId = false
              this.dcbTableDetail[i] = val
              this.$set(this.dcbTableDetail, i, this.dcbTableDetail[i])
              break
            }
          }
          if (isId) {
            val.ids = Math.random(0, 1000) + Math.random(0, 1000)
            this.dcbTableDetail.push(val)
          }
        } else {
          for (let i = 0; i < this.dcbTableDetail.length; i++) {
            const element = this.dcbTableDetail[i]
            if (val.id === element.id) {
              this.dcbTableDetail[i] = val
              this.$set(this.dcbTableDetail, i, this.dcbTableDetail[i])
              break
            }
          }
        }
      } else if (this.openDialogType == 'general') {
        console.log(11, val)
        if (!val.id) {
          let isId = true
          for (let i = 0; i < this.generalTableDetail.length; i++) {
            const element = this.generalTableDetail[i]
            if (val.ids && val.ids === element.ids) {
              isId = false
              this.generalTableDetail[i] = val
              this.$set(this.generalTableDetail, i, this.generalTableDetail[i])
              break
            }
          }
          if (isId) {
            val.ids = Math.random(0, 1000) + Math.random(0, 1000)
            this.generalTableDetail.push(val)
          }
        } else {
          for (let i = 0; i < this.generalTableDetail.length; i++) {
            const element = this.generalTableDetail[i]
            if (val.id === element.id) {
              this.generalTableDetail[i] = val
              this.$set(this.generalTableDetail, i, this.generalTableDetail[i])
              break
            }
          }
        }
      }
    }
  }
}
</script>
