<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}

.item-row {
  margin: 0.5rem 0.5rem 1rem 0;
  width: 20%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.item-row > span {
  margin: 0 15px;
}

.input_has_unit {
  width: 180px;
}

.dialogContent::-webkit-scrollbar {
  width: 12px;
}

.dialogContent::-webkit-scrollbar-track {
  border-radius: 10px;
}

.dialogContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.dialogContent::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}

.dialogContent {
  flex: 1;
  overflow: auto;
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    title="病变部位编辑"
    v-show="isShow"
    @close="Close"
  >
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialogContent">
      <div class="top-item-row">
        <div class="item-row">
          <div class="input-form-item-label">病变部位</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/PB_lesion_site"
            :value.sync="coronaryDetail.lesionSite"
          />
        </div>
        <div class="item-row">
          <div class="input-form-item-label">病变部位方向</div>
          <cqt-radio :value.sync="coronaryDetail.lesionSiteDirection" :isReadonly="isReadonly">
            <el-radio label="左侧">左侧</el-radio>
            <el-radio label="右侧">右侧</el-radio>
          </cqt-radio>
        </div>

        <div class="item-row">
          <div class="input-form-item-label">病变特点</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/PB_pathological_feature"
            :value.sync="coronaryDetail.pathologicalFeatures"
          />
        </div>

        <div class="item-row">
          <div class="input-form-item-label">
            <div style="display: flex">
              <span>ISR阻塞分级</span>
              <div
                @mouseenter="ISRIsExitHover = true"
                @mouseleave="ISRIsExitHover = false"
                class="menus"
                style="float: right"
              >
                <i class="el-icon-warning" v-show="!ISRIsExitHover"></i>
                <i v-show="ISRIsExitHover" class="el-icon-warning" slot="reference" />
                <el-popover
                  placement="bottom"
                  width="300"
                  trigger="manual"
                  v-model="ISRIsExitHover"
                >
                  <img :src="isrblockingExitActive" style="width: 300px; height: 150px" />
                </el-popover>
              </div>
            </div>
          </div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/PB_ISR"
            :value.sync="coronaryDetail.iSRBlocking"
          />
        </div>
        <div class="item-row"></div>
      </div>
      <div class="top-item-row">
        <div class="item-row">
          <div class="input-form-item-label">支架是否发生折断</div>
          <cqt-radio :value.sync="coronaryDetail.bracketBroken" :isReadonly="isReadonly">
            <el-radio label="是" @change="changeBracketBroken">是</el-radio>
            <el-radio label="否" @change="changeBracketBroken">否</el-radio>
          </cqt-radio>
        </div>
        <div class="item-row">
          <div class="input-form-item-label">
            <div style="display: flex">
              <span>折断类型</span>
              <div
                @mouseenter="breakIsExitHover = true"
                @mouseleave="breakIsExitHover = false"
                class="menus"
              >
                <i class="el-icon-warning" v-show="!breakIsExitHover"></i>
                <i v-show="breakIsExitHover" class="el-icon-warning" slot="reference" />
                <el-popover
                  placement="bottom"
                  width="250"
                  trigger="manual"
                  v-model="breakIsExitHover"
                >
                  <img :src="breakTypeExitActive" style="width: 250px; height: 350px" />
                </el-popover>
              </div>
            </div>
          </div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/PB_break_type"
            :value.sync="coronaryDetail.breakType"
            :disabled="breakType_status"
          />
        </div>

        <div class="item-row">
          <div class="input-form-item-label">
            <div style="display: flex">
              <span>钙化程度</span>
              <div
                @mouseenter="CalcificationIsExitHover = true"
                @mouseleave="CalcificationIsExitHover = false"
                class="menus"
                style="float: right"
              >
                <i class="el-icon-warning" v-show="!CalcificationIsExitHover"></i>
                <i v-show="CalcificationIsExitHover" class="el-icon-warning" slot="reference" />
                <el-popover
                  placement="bottom"
                  width="460"
                  trigger="manual"
                  v-model="CalcificationIsExitHover"
                >
                  <img :src="calcificationExitActive" style="width: 460px; height: 120px" />
                </el-popover>
              </div>
            </div>
          </div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/PB_calcification_degree"
            :value.sync="coronaryDetail.degreeOfCalcification"
          />
        </div>

        <div class="item-row">
          <div class="input-form-item-label">手术部位</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/PB_lesion_site"
            :value.sync="coronaryDetail.operationPosition"
          />
        </div>
        <div class="item-row">
          <!-- <div class="input-form-item-label">手术方式</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/PB_operation_method"
            :value.sync="coronaryDetail.operationName"
          /> -->
        </div>
      </div>
      <div class="top-item-row">
        <el-col :span="4">
          <cqt-radio :value.sync="handlingSuggestions">
            <el-radio label="本次治疗">本次治疗</el-radio>
          </cqt-radio>
        </el-col>
        <el-col style="margin-left: 45px" :span="20">
          <current-checkbox-select
            v-if="handlingSuggestions === '本次治疗'"
            v-model="currentTreatment"
            :currentTreatment="this.currentTreatment"
          ></current-checkbox-select>
        </el-col>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'
import CurrentCheckboxSelect from './CurrentCheckboxSelect.vue'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'

export default {
  name: 'CoronaryInfoInputDialog',
  components: {
    CmDialogBorder,
    CurrentCheckboxSelect,
    CqtInput,
    CqtSelect,
    CqtRadio
  },

  props: {
    formId: {
      type: String,
      require: true
    },
    initDefaultData: {
      // 初始化数据
      type: Object,
      default: () => ({})
    },
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      handlingSuggestions: '',
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      currentTreatment: '',
      // coronaryDetail: {
      //   degreeValue: 0,
      //   handlingSuggestions: '',
      //   interventionHistory: null,
      //   lengthValue: 0,
      //   lesionFeature: null,
      //   lesionKind: null,
      //   lesionRegion: null,
      //   stentingHistory: null,
      //   stentingType: null,
      //   timi: null,
      //   coronaryEquipmentDetail: null
      // },
      coronaryDetail: {
        lesionSite: null,
        pathologicalFeatures: null,
        iSRBlocking: null,
        bracketBroken: null,
        breakType: null,
        degreeOfCalcification: null,
        operationPosition: null
        // operationName: null
      },
      selectRowList: [],
      operationId: undefined,
      // ------------
      ISRIsExitHover: false, //是否悬浮到图标上
      isrblockingExitActive: require('@/assets/img/nerve/ISRzuse.png'),
      breakType_status: true,
      breakIsExitHover: false, //是否悬浮到图标上
      breakTypeExitActive: require('@/assets/img/nerve/break.png'),
      CalcificationIsExitHover: false, //是否悬浮到图标上
      calcificationExitActive: require('@/assets/img/nerve/calcification.png')
    }
  },
  mounted() {
    this.operationId = this.$route.query.operationId
  },
  computed: {},
  watch: {
    isReadonly: {
      handler(val) {}
    },
    handlingSuggestions: {
      handler(val) {
        this.coronaryDetail.handlingSuggestions = val
      }
    },
    currentTreatment: {
      handler(val) {
        this.coronaryDetail.handlingSuggestions = '本次治疗'
        if (val && val.length > 0) {
          this.coronaryDetail.handlingSuggestions = ['本次治疗', ...val.split('|')].join('+')
        }
      }
    }
  },
  methods: {
    Show(bloodVessel) {
      this.coronaryDetail = {}
      if (bloodVessel) {
        this.coronaryDetail = deepClone(bloodVessel)
      }
      let sugs = this.coronaryDetail.handlingSuggestions?.split('+') || []
      console.log(sugs)
      this.handlingSuggestions = sugs[0] || ''
      if (sugs.length > 0) {
        this.currentTreatment = sugs?.slice(1).join('|')
      }
      this.isShow = true
    },
    Close() {
      this.isShow = false
    },

    async Save() {
      if (this.coronaryDetail.lesionSite === null || this.coronaryDetail.lesionSite === '') {
        return this.$message.warning('病变部位不能为空')
      }
      var idx = this.coronaryDetail.handlingSuggestions?.indexOf('++') || -1
      if (idx != -1) {
        var a = this.coronaryDetail.handlingSuggestions.substr(0, idx + 1)
        var b = this.coronaryDetail.handlingSuggestions.substr(idx + 2)
        this.coronaryDetail.handlingSuggestions = a + b
      }
      this.$emit('save', this.coronaryDetail)
      this.Close()
    },
    changeBracketBroken(val) {
      if (val === '否') {
        this.coronaryDetail.breakType = null
        this.breakType_status = true
      } else {
        this.breakType_status = false
      }
    }
  }
}
</script>
