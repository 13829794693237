<template>
  <div class="valve-module-main-content main-content" v-loading="loading">
    <div class="crt-content">
      <div class="save-button" v-show="!isReadonly">
        <el-button
          @click="open_save"
          size="mini"
          type="success"
          class="commonBtn"
          :disabled="isReadonly"
        >
          保存
        </el-button>
      </div>

      <el-button @click="isReadonly = !isReadonly" size="mini" class="commonBtn" type="primary">
        {{ isReadonly === true ? '编辑' : '取消' }}
      </el-button>
      <el-button
        icon="el-icon-s-platform"
        @click="infoPreview"
        size="mini"
        class="commonBtn"
        type="primary"
      >
        报告预览
      </el-button>
      <el-button
        icon="el-icon-edit"
        @click="reportSign"
        size="mini"
        class="commonBtn"
        type="success"
      >
        {{ signStatus ? '解签' : '报告签名' }}
      </el-button>
    </div>

    <div class="body">
      <BasicForm :isReadonly="isReadonly" :basicData="coronaryInfo" ref="BasicForm" />

      <!-- 血管信息录入 -->
      <div class="vascular-information-entry">
        <CoronaryInfoInput
          :allDetail="allDetail"
          :formId="formData.formId"
          :isReadonly="isReadonly"
          ref="thrombusInfo"
        ></CoronaryInfoInput>
      </div>

      <!-- 头部卡片区域 -->
      <div class="valve-basic-information">
        <div class="valve-basic-information-muticards remarks-color">
          <span class="inner-title">术后评估</span>
          <div class="muticards">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">术后膝下Run-off评分</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  style="margin-left: 15px"
                  activeUrl="/v1/webconsole/comboData/get/PB_Run_Off_grade"
                  :value.sync="postoperationDetail.runOff"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">
                  <span>术后裸下血管评分</span>
                  <div
                    @mouseenter="daVascularIsExitHover = true"
                    @mouseleave="daVascularIsExitHover = false"
                    class="menus"
                    style="float: right"
                  >
                    <i class="el-icon-warning" v-show="!daVascularIsExitHover"></i>
                    <i v-show="daVascularIsExitHover" class="el-icon-warning" slot="reference" />
                    <el-popover
                      placement="bottom"
                      width="400"
                      trigger="manual"
                      v-model="daVascularIsExitHover"
                    >
                      <img :src="daVascularExitActive" style="width: 400px; height: 100px" />
                    </el-popover>
                  </div>
                </div>
                <cqt-select
                  :isReadonly="isReadonly"
                  style="margin-left: 15px"
                  activeUrl="/v1/webconsole/comboData/get/PB_subankle_vascular_score"
                  :value.sync="postoperationDetail.vascular"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">术后ABI</div>
                <cqt-input
                  :isReadonly="isReadonly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="text"
                  size="mini"
                  v-model="postoperationDetail.ABI"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">
                  <span>症状改善情况</span>
                  <div
                    @mouseenter="daimprovementIsExitHover = true"
                    @mouseleave="daimprovementIsExitHover = false"
                    class="menus"
                    style="float: right"
                  >
                    <i class="el-icon-warning" v-show="!daimprovementIsExitHover"></i>
                    <i v-show="daimprovementIsExitHover" class="el-icon-warning" slot="reference" />
                    <el-popover
                      placement="bottom"
                      width="400"
                      trigger="manual"
                      v-model="daimprovementIsExitHover"
                    >
                      <img :src="daimprovementExitActive" style="width: 400px; height: 100px" />
                    </el-popover>
                  </div>
                </div>
                <cqt-select
                  :isReadonly="isReadonly"
                  style="margin-left: 15px"
                  activeUrl="/v1/webconsole/comboData/get/PB_symptom_improvement"
                  :value.sync="postoperationDetail.improvement"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 并发症 -->
      <ComplicationForm
        :isReadonly="isReadonly"
        :complicationData="coronaryInfo"
        complicationUrl="/v1/webconsole/comboData/get/PB_intraoperative_complication"
        finalDiagnosisUrl="/v1/webconsole/comboData/get/PB_post_diagnosis"
        ref="ComplicationForm"
      />
      <!-- 手术记录 -->
      <div class="surgery-procedure">
        <surgery-procedure
          :isReadOnly="isReadonly"
          :templateData="templateProcedureData"
          :surgeryInfo="surgeryInfo?.studyProcess"
          :operation="operation"
          ref="SurgeryProcedure"
          @infoPreview="infoPreview"
        ></surgery-procedure>
      </div>

      <!-- <div class="surgery-result">
        <surgery-result
          :isReadOnly="isReadonly"
          :templateData="templateResultData"
          :surgeryInfo="surgeryInfo?.studyResult"
          :operation="operation"
          ref="result"
        ></surgery-result>
      </div> -->

      <div class="post-operative-medical-diagnosis">
        <post-medical-diagnosis
          :isReadOnly="isReadonly"
          :templateData="templateDiagnosisData"
          :surgeryInfo="surgeryInfo"
          :operation="operation"
          ref="PostMedicalDiagnosis"
        />
      </div>
      <!-- 签名 -->
      <sign-report :operation="operation" @signComplete="signComplete" ref="SignReport" />
      <!-- pdf -->
      <el-dialog title="报告详情" :visible.sync="dialogVisible" width="70%" :modal="false">
        <div>
          <iframe :src="this.report_address" style="height: 890px; width: 100%"></iframe>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SurgeryProcedure from '../components/SurgeryProcedure.vue'
import SurgeryResult from '../components/SurgeryResult.vue'
import PostMedicalDiagnosis from '../components/PostMedicalDiagnosis.vue'

import SignReport from '../components/SignReport.vue'
import CoronaryInfoInput from './components/CoronaryInfoInput.vue'

import { PDF_HOST } from '../../../utils/globalVariable'

import { mapState } from 'vuex'

// cqt
import CqtInput from '../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../components/cqt/CqtRadio.vue'

import BasicForm from './components/BasicForm.vue'
import ComplicationForm from '../components/ComplicationForm.vue'
export default {
  components: {
    SurgeryProcedure,
    SurgeryResult,
    PostMedicalDiagnosis,
    SignReport,
    CqtSelect,
    CqtInput,
    CqtRadio,
    BasicForm,
    ComplicationForm,
    CoronaryInfoInput
  },
  data() {
    return {
      report_address: '',
      dialogVisible: false,
      reportInfo: null,
      signStatus: false,
      isrblockingExitActive: require('@/assets/img/nerve/ISRzuse.png'),
      breakTypeExitActive: require('@/assets/img/nerve/break.png'),
      calcificationExitActive: require('@/assets/img/nerve/calcification.png'),
      ISRIsExitHover: false, //是否悬浮到图标上
      breakIsExitHover: false, //是否悬浮到图标上
      CalcificationIsExitHover: false, //是否悬浮到图标上
      balloonSurgeryExitActive: require('@/assets/img/nerve/balloonSurgery.png'),
      balloonSurgeryIsExitHover: false, //是否悬浮到图标上
      balloonVascularExitActive: require('@/assets/img/nerve/vascularScore.png'),
      balloonVascularIsExitHover: false, //是否悬浮到图标上
      balloonImprovementExitActive: require('@/assets/img/nerve/balloonImprovement.png'),
      balloonImprovementIsExitHover: false, //是否悬浮到图标上
      dcbsurgeryExitActive: require('@/assets/img/nerve/balloonSurgery.png'),
      dcbsurgeryIsExitHover: false, //是否悬浮到图标上
      dcbvascularExitActive: require('@/assets/img/nerve/vascularScore.png'),
      dcbvascularIsExitHover: false, //是否悬浮到图标上
      dcbimprovementExitActive: require('@/assets/img/nerve/balloonImprovement.png'),
      dcbimprovementIsExitHover: false, //是否悬浮到图标上
      dcbsandwichTypingExitActive: require('@/assets/img/nerve/dcbsandwichTyping.png'),
      dcbsandwichTypingIsExitHover: false, //是否悬浮到图标上
      supportSurgeryExitActive: require('@/assets/img/nerve/balloonSurgery.png'),
      supportSurgeryIsExitHover: false, //是否悬浮到图标上
      supportImprovementExitActive: require('@/assets/img/nerve/balloonImprovement.png'),
      supportImprovementIsExitHover: false, //是否悬浮到图标上
      daimprovementExitActive: require('@/assets/img/nerve/balloonImprovement.png'),
      daimprovementIsExitHover: false, //是否悬浮到图标上
      dasurgeryExitActive: require('@/assets/img/nerve/balloonSurgery.png'),
      dasurgeryIsExitHover: false, //是否悬浮到图标上
      supportVascularExitActive: require('@/assets/img/nerve/vascularScore.png'),
      supportVascularIsExitHover: false, //是否悬浮到图标上
      daVascularExitActive: require('@/assets/img/nerve/vascularScore.png'),
      daVascularIsExitHover: false, //是否悬浮到图标上
      isReadonly: false,
      hasThrombus: '无',
      breakType_status: true,
      loading: false,
      peripheralVesselsId: '-1',
      selectedRow: null,
      tableKey: null,
      arteryInfo: {
        id: null,
        lesionSiteDirection: null, //病变部位方向
        lesionSite: null, //病变部位位置
        initialArterialApproach: null, //初始动脉入路
        initialArterySheath: null, //初始动脉鞘管
        reverseArterialDirection: null, //逆向动脉入路方向
        reverseArterialApproach: null, //逆向动脉入路位置
        reverseArterySheath: null, //逆向动脉鞘管
        byWay: null, //通过方式
        pathologicalFeatures: null, //病变特点
        isrblocking: null, //ISR阻塞分级
        bracketBroken: null, //支架是否发生折断
        breakType: null, //折断类型
        degreeOfCalcification: null, //钙化程度
        operationPosition: null, //手术部位
        operationName: null, //手术方式
        thrombus: null, //有无血栓
        dARunOff: null, //术后膝下Run-off评分
        dAVascular: null, //术后踝下血管评分
        daabi: null, //术后ABI
        dAImprovement: null //症状改善情况
      },
      // 手术经过结果信息
      operationName: '外周血管介入',
      surgeryInfo: null,
      operation: 'PeripheralVessels',
      templateProcedureData: {
        templateName: '手术经过'
      },
      templateResultData: {
        templateName: '手术结果'
      },
      templateAdviceData: {
        templateName: '术后医嘱'
      },
      templateDiagnosisData: {
        templateName: '术后诊疗计划'
      },
      // 术后评估
      postoperationDetail: {
        runOff: null, //术后膝下Run-off评分
        vascular: null, //术后踝下血管评分
        ABI: null, //术后ABI
        improvement: null //症状改善情况
      },
      dcbTableDetail: [],
      stentImplantationTableDetail: [],
      coronaryInfo: {},
      menuListData: null,
      allDetail: {},
      formData: {
        formId: '',
        id: '',
        items: []
      },
      universalStudy: {
        // 公共字段
        postDiagnosis: undefined, //术后诊断
        studyProcess: undefined, //手术经过
        // studyResult: undefined, //手术结果
        o2: undefined, //吸氧方式
        anesthesiaType: undefined, //麻醉方式
        vascularApproach: undefined, //血管入路
        otherApproachType: undefined, // 其他入路方式
        approachType: undefined, //入路方式
        superHardGW: undefined, //超硬导丝
        contrastAgent: undefined, //造影剂剂量(ml)
        rayQuantity: undefined, //射线量(mGy)
        exposureTime: undefined, //曝光时间(min)
        sheathSize: undefined, //鞘管大小
        sheathGoInTime: undefined, //鞘置入时间
        sheathOutTime: undefined, //鞘拔除时间
        sheathLength: undefined, //鞘置入时长 min
        isBleeding: '否', // 是否出血
        outTransAmount: undefined, //出血量
        transfusion: undefined, //是否输血
        transfusionComponent: undefined, //输血成分
        transfusionAmount: undefined, //输血量
        protamine: undefined, //鱼精蛋白
        protamineDose: undefined, //鱼精蛋白剂量（mg）
        vesslClosure: undefined, //血管闭合方式
        vesselObturator: undefined, //血管闭合器种类
        vesselObturatorOther: undefined, //血管闭合器种类其他
        intervention: undefined, //特殊操作
        pacingThreshold: undefined, //临时起搏阈值
        pacingFrequency: undefined, //临时起搏频率
        pacingOutput: undefined, //临时起搏输出
        pacingPerception: undefined, //临时起搏感知
        patientFeedback: undefined, //术后患者反映
        skinExamination: undefined, //术后皮肤检查
        vascularPulsation: undefined, //术后穿刺处血管搏动
        postOperativeBraking: undefined, //术后制动
        transferMethod: undefined, //转运方式
        postoperativeDestination: undefined, //术后去向
        riskAssessment: undefined, //风险评估
        complication: undefined, //并发症
        postAdvice: undefined, //术后医嘱
        postNotes: undefined, //术后注意事项
        treatmentPlanId: undefined, //术后诊疗计划id
        treatmentPlan: undefined, //术后诊疗,

        otherTransfusionComponent: undefined, //其他输血成分描述,
        wholeBloodVolume: undefined, //全血输血量,
        plasmaVolume: undefined, //血浆输血量,
        plateletVolume: undefined, //血小板输血量,
        redBloodCellVolume: undefined, //红细胞输血量,
        otherVolume: undefined, //其他成分血输血量,
        otherSuperHardGW: null, // 超硬导丝其他描述
        vesslClosureOther: undefined, // 血管闭合方式其他
        // postDiagnosisOther: undefined, // 术后诊断其他
        // complicationOther: undefined, // 并发症其他

        occluderType: undefined, // 封堵器类型
        occluderOtherType: undefined, // 封堵器类型其他
        staplerType: undefined, // 缝合器类型
        staplerOtherType: undefined, // 缝合器类型其他
        pressureTime: undefined // 压迫时间
      },
      coronaryDetail: {
        elementId: 'peripheralVesselsInformation',
        value: null,
        extendTable: null
      }
    }
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {},
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.getMenuIndex()
        }
      },
      immediate: true
    }
    // allDetail: {
    //   async handler(val) {
    //     if (val) {
    //       const getData = (name) => {
    //         console.log(11,this.allDetail);
    //         let params = new FormData()
    //         params.append('elementName', name)
    //         return this.$api
    //           .post(
    //             `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail[name].id}`,
    //             params
    //           )
    //           .then(
    //             ({ data: res }) => {
    //               if (res && res.data && res.data.length > 0) {
    //                 return res.data
    //               }
    //             },
    //             () => {}
    //           )
    //       }
    //       let balloonDilatationDetailData = await getData('peripheralVesselsBalloon')
    //       if (balloonDilatationDetailData?.length > 0) {
    //         this.balloonDilatationDetail = balloonDilatationDetailData[0]
    //       }
    //       let DCBData = await getData('peripheralVesselsDCB')
    //       if (DCBData?.length > 0) {
    //         this.dcbDetail = DCBData[0]
    //       }
    //       let DCBTableData = await getData('peripheralVesselsDCBDetails')
    //       if (DCBTableData?.length > 0) {
    //         this.dcbTableDetail = DCBTableData
    //       }
    //       let stentData = await getData('peripheralVesselsImplantation')
    //       if (stentData?.length > 0) {
    //         this.stentImplantationDetail = stentData[0]
    //       }
    //       let stentTableData = await getData('peripheralVesselsStentImplantation')
    //       if (stentTableData?.length > 0) {
    //         this.stentImplantationTableDetail = stentTableData
    //       }
    //       let DAData = await getData('peripheralVesselsDA')
    //       if (DAData?.length > 0) {
    //         this.daDetail = DAData[0]
    //       }
    //     }
    //   }
    // }
  },

  created() {},
  methods: {
    getMenuIndex() {
      if (this.$store.state.menuList.length > 0 && !this.$route.path.includes('follow')) {
        for (let i = 0; i < this.$store.state.menuList.length; i++) {
          const element = this.$store.state.menuList[i]
          if (element.menuCode.includes(this.$route.path)) {
            this.menuListData = element
          }
        }
        this.loading = true
        this.getTable()
      } else {
        let url = `/v1/webconsole/study/operation/menu/${this.info.uuid}`
        this.$api.get(url).then((res) => {
          let menuList = []
          if (res.data && res.data.data) {
            menuList = res.data.data.map((item) => {
              return {
                ...item,
                menuCode: `/Detail/${item.menuCode}`,
                code: item.menuCode
              }
            })
            for (let i = 0; i < menuList.length; i++) {
              const element = menuList[i]
              if (
                element.menuCode.includes(
                  JSON.parse(localStorage.getItem('followPath')).replace('/follow/info', '')
                )
              ) {
                this.menuListData = element
              }
            }
            this.getTable()
          }
        })
      }
    },
    // 查询基本数据
    getTable() {
      let url = `/v1/webconsole/eform/universal/form/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api.get(url).then(({ data: res }) => {
        if (res && res.data) {
          this.universalForm = res.data
          this.formData.formId = res.data[0].formId
          let formUrl = `/v1/webconsole/eform/universal/formdata/${this.info.uuid}/${this.menuListData.moduleId}`
          let data = {
            formId: this.formData.formId
          }
          this.$api.get(formUrl, data).then(({ data: res }) => {
            if (res && res.data) {
              // 如果没有数据则使用初始化
              if (res.data.length !== 0) {
                this.formList = res.data
                this.formData.id = res.data[0].id
                this.peripheralVesselsId = res.data[0].id
                this.getTableData()
              }
              this.getReportInfo()
            }
          })

          // 查询基本数据
          this.$api
            .get(
              `/v1/webconsole/study/operation/universal/${this.info.uuid}?moduleCode=${this.menuListData.code}`,
              data
            )
            .then(({ data: res }) => {
              if (res && res.data) {
                // 如果没有数据则使用初始化
                if (res.data) {
                  let obj = {}
                  for (let i in res.data) {
                    for (let j in this.universalStudy) {
                      if (j === i) {
                        obj[i] = res.data[i]
                      }
                    }
                  }
                  this.coronaryInfo = { ...this.coronaryInfo, ...obj }
                  this.surgeryInfo = obj
                }
              }
            })
        }
      })
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 获取表单数据
    getTableData() {
      let url = `/v1/webconsole/eform/fields/${this.formList[0].formId}/${this.formList[0].id}`
      this.$api.get(url).then(({ data: res }) => {
        let obj = {}
        if (res && res.data) {
          delete res.data.vascularApproach
          for (let i in res.data) {
            obj[i] = res.data[i].valueString
            if (res.data[i].dataType === 'dataTable') {
              res.data[i].extendTable = []
            }
            if (this.postoperationDetail.hasOwnProperty(i)) {
              this.postoperationDetail[i] = res.data[i].valueString
            }
          }
          this.coronaryInfo = { ...this.coronaryInfo, ...obj }
          this.allDetail = res.data
          this.hasThrombus = obj.thrombus
        }
      })
    },
    getReportInfo() {
      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.reportInfo = res.data.data
            if (res.data.data?.signed === 'Y') {
              this.signStatus = true
            }
          }
        })
    },
    // 签名
    reportSign() {
      // 解签
      if (this.signStatus) {
        this.$refs.SignReport.showRelievePwdDialog()
      } else {
        this._save(() => {
          this.$refs.SignReport.getReportSignType()
        })
      }
    },
    signComplete(val) {
      this.signStatus = val
    },
    // 报告
    infoPreview() {
      const pdfLoading = this.$loading({
        lock: true,
        text: '报告加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      this.report_address = null

      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then(
          (res) => {
            pdfLoading.close()
            if (!res.data.data || res.data.data?.signed === 'N') {
              // 生成手术报告单
              this._save(() => {
                this.$api
                  .get(
                    `/v1/webconsole/report/save/doctor/${this.info.uuid}/${this.menuListData.code}`
                  )
                  .then(
                    (response) => {
                      pdfLoading.close()
                      if (response.data && response.data.data) {
                        if (!response.data.data.pdfFileName) {
                          return this.$message.info('暂无报告')
                        }
                        var s = encodeURI(response.data.data.pdfFileName)
                        if (response.data.data.pdfFileName.includes('http')) {
                          this.report_address = s
                        } else {
                          this.report_address =
                            PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
                        }
                        this.dialogVisible = true
                      } else {
                        return this.$message.info('暂无报告')
                      }
                    },
                    () => {
                      pdfLoading.close()
                      return this.$message.error('生成报告出错！')
                    }
                  )
                  .catch((err) => {
                    pdfLoading.close()
                    return this.$message.error('生成报告出错！')
                  })
              })
            } else {
              var s = encodeURIComponent(res.data.data.pdfFileName)
              if (res.data.data.pdfFileName.includes('http')) {
                this.report_address = s
              } else {
                this.report_address = PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
              }
              this.dialogVisible = true
            }
          },
          () => {
            pdfLoading.close()
            return this.$message.error('生成报告出错！')
          }
        )
    },
    isThrombus(val) {
      this.hasThrombus = val
    },
    open_save() {
      // console.log('基本信息', this.$refs.BasicForm.aorticInfo)
      // console.log('血栓信息', this.$refs.thrombusInfo.thrombusTableData)
      // console.log('普通球囊信息', this.balloonDilatationDetail)
      // console.log('DCB列表', this.dcbTableDetail)
      // console.log('DCB', this.dcbDetail)
      // console.log('支架列表', this.stentImplantationTableDetail)
      // console.log('支架', this.stentImplantationDetail)
      // console.log('DA', this.daDetail)
      // console.log('hasThrombus', this.hasThrombus)
      if (this.isReadonly) {
        return this.$message({
          message: '报告已签名,无法进行编辑操作',
          type: 'warning'
        })
      }
      this.$confirm('是否保存动脉基本信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._save()
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消保存'
          })
        })
    },
    _save(callback) {
      this.formData.items = [] // 重置item
      let aorticInfo = this.$refs.BasicForm.getAllBasicData() // 全部基础信息
      let complicationForm = this.$refs.ComplicationForm.aorticInfo
      complicationForm = {
        ...complicationForm,
        complication: complicationForm.complication?.join('|'),
        postDiagnosis: complicationForm.postDiagnosis || ''
      } // 并发症
      this.loading = true
      let differentFields = {}

      for (let ele in this.universalStudy) {
        // 按要求分割对象
        for (let item in aorticInfo) {
          if (item === ele) {
            this.universalStudy[ele] = aorticInfo[ele]
          }
        }
      }
      for (let item in aorticInfo) {
        // 剩下没有相同字段的对象
        if (!this.universalStudy?.hasOwnProperty(item)) {
          differentFields[item] = aorticInfo[item]
        }
      }
      for (let item in this.postoperationDetail) {
        // 剩下没有相同字段的对象
        if (!this.universalStudy?.hasOwnProperty(item)) {
          differentFields[item] = this.postoperationDetail[item]
        }
      }
      for (let i in differentFields) {
        this.formData.items.push({
          elementId: i,
          value: differentFields[i],
          extendTable: null
        })
      }

      this.handleNewData()
      this.formData.universalStudy = {
        // 基础字段
        ...this.universalStudy,
        ...complicationForm,
        moduleId: this.menuListData.moduleId,
        operationId: this.currentPatient.uuid,
        treatmentPlanId: this.$refs.PostMedicalDiagnosis.treatmentPlanId, // 术后诊疗计划
        treatmentPlan: this.$refs.PostMedicalDiagnosis.treatmentPlan, // 术后诊疗计划
        studyProcess: this.$refs.SurgeryProcedure.studyRecord // 手术经过
        // studyResult: this.$refs.result.studyResult // 手术结果
      } // universalStudy字段
      let url = `/v1/webconsole/eform/universal/save/${this.currentPatient.uuid}/${this.menuListData.moduleId}`
      this.$api
        .post(url, this.formData)
        .then(
          ({ data: data }) => {
            if (data.status === 200) {
              this.formData.id = data.data.id
              this.peripheralVesselsId = data.data.id
              this.loading = false
              this.$bus.$emit('saveFormInfo')
              !callback && this.$message.success('保存成功')
            }
          },
          () => {
            this.loading = false
            return !callback && this.$message.error('保存出错')
          }
        )
        .finally(() => {
          callback && callback()
        })
    },
    handleNewData() {
      if (this.$refs.thrombusInfo.tableData.length !== 0) {
        const Arr = this.$refs.thrombusInfo.tableData.map((element) => {
          return this.convertNewElement(element)
        })
        this.coronaryDetail.extendTable = Arr
        this.formData.items.push(this.coronaryDetail)
      }
    },
    convertNewElement(element) {
      delete element.id
      const arr = Object.entries(element).map(([i, value]) => {
        if (value && typeof value !== 'string') {
          let subDetail
          if (
            i === 'peripheralVesselsDCBDetails' ||
            i === 'peripheralVesselsStentImplantation' ||
            i === 'peripheralVesselsBalloonDetail'
          ) {
            subDetail = element[i]?.map((ele) => {
              return Object.entries(ele).map(([k, v]) => ({
                elementId: k,
                value: v,
                extendTable: null
              }))
            })
          } else {
            subDetail = [
              Object.entries(value).map(([j, ele]) => ({
                elementId: j,
                value: ele,
                extendTable: null
              }))
            ]
          }
          return {
            elementId: i,
            value: null,
            extendTable: subDetail
          }
        } else {
          return {
            elementId: i,
            value: value,
            extendTable: null
          }
        }
      })
      return arr
    },
    _rowClick(row) {
      if (this.isReadonly) return
      this.selectedRow = row
    },

    _clearPOBA() {
      if (this.isReadonly) return
      this.balloonDilatationDetail = {
        operativeStyle: null,
        balloonSurgery: null,
        balloonRunOff: null,
        balloonVascular: null,
        balloonABI: null,
        balloonImprovement: null,
        numberBalloons: null,
        balloonMinorComplications: null,
        balloonSeriousCom: null
      }
    },

    //dcb
    _clearDCB() {
      if (this.isReadonly) return
      this.dcbDetail = {
        dCBSurgery: null,
        dCBRunOff: null,
        dCBVascular: null,
        dcbabi: null,
        dCBImprovement: null,
        dCBMinorComplications: null,
        dCBSeriousCom: null,
        dCBSandwichTyping: null
      }
    },
    DCBInsert() {
      if (this.isReadonly) return
      this.$refs.dcbDetailDialog.Show()
    },
    DCBDelete() {
      if (this.isReadonly) return
      if (this.selectedRow) {
        if (this.selectedRow.id) {
          for (let i = 0; i < this.dcbTableDetail.length; i++) {
            const element = this.dcbTableDetail[i]
            if (this.selectedRow.id === element.id) {
              this.dcbTableDetail.splice(i, 1)
              break
            }
          }
        } else {
          for (let i = 0; i < this.dcbTableDetail.length; i++) {
            const element = this.dcbTableDetail[i]
            if (this.selectedRow.id === element.id) {
              this.dcbTableDetail.splice(i, 1)
              break
            }
          }
        }
      }
    },
    DCB_dialogSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.dcbTableDetail.length; i++) {
          const element = this.dcbTableDetail[i]
          if (val.id && val.id === element.id) {
            isId = false
            this.dcbTableDetail[i] = val
            this.$set(this.dcbTableDetail, i, this.dcbTableDetail[i])
          }
        }
        if (isId) {
          val.id = Math.random(0, 1000) + Math.random(0, 1000)
          this.dcbTableDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.dcbTableDetail.length; i++) {
          const element = this.dcbTableDetail[i]
          if (val.id === element.id) {
            this.dcbTableDetail[i] = val
            this.$set(this.dcbTableDetail, i, this.dcbTableDetail[i])
          }
        }
      }
    },

    // 支架植入
    _clearStentImplantation() {
      if (this.isReadonly) return
      this.stentImplantationDetail = {
        supportImplantation: null, //支架植入情况
        supportLength: null, //支架总长度
        supportPosition: null, //支架位置
        crossKnee: null, //支架是否跨膝
        supportSurgery: null, //手术是否成功
        supportRunOff: null, //术后膝下Run-off评分
        supportVascular: null, //术后踝下血管评分
        supportABI: null, //术后ABI
        supportImprovement: null, //症状改善情况
        supportMinorComplications: null, //围手术期中小并发症
        supportSeriousCom: null //围手术期严重并发症
      }
    },
    stentImplantationInsert() {
      if (this.isReadonly) return

      this.$refs.stentImplantationDialog.Show()
    },
    stentImplantationDelete() {
      if (this.isReadonly) return

      if (this.selectedRow) {
        if (this.selectedRow.id) {
          for (let i = 0; i < this.stentImplantationTableDetail.length; i++) {
            const element = this.stentImplantationTableDetail[i]
            if (this.selectedRow.id === element.id) {
              this.stentImplantationTableDetail.splice(i, 1)
              break
            }
          }
        } else {
          for (let i = 0; i < this.stentImplantationTableDetail.length; i++) {
            const element = this.stentImplantationTableDetail[i]
            if (this.selectedRow.id === element.id) {
              this.stentImplantationTableDetail.splice(i, 1)
              break
            }
          }
        }
      }
    },
    stentImplantation_dialogSave(val) {
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.stentImplantationTableDetail.length; i++) {
          const element = this.stentImplantationTableDetail[i]
          if (val.id && val.id === element.id) {
            isId = false
            this.stentImplantationTableDetail[i] = val
            this.$set(this.stentImplantationTableDetail, i, this.stentImplantationTableDetail[i])
          }
        }
        if (isId) {
          val.id = Math.random(0, 1000) + Math.random(0, 1000)
          this.stentImplantationTableDetail.push(val)
        }
      } else {
        for (let i = 0; i < this.stentImplantationTableDetail.length; i++) {
          const element = this.stentImplantationTableDetail[i]
          if (val.id === element.id) {
            this.stentImplantationTableDetail[i] = val
            this.$set(this.stentImplantationTableDetail, i, this.stentImplantationTableDetail[i])
          }
        }
      }
    },

    // 斑块减容
    _clearPA() {
      if (this.isReadonly) return

      this.daDetail = {
        volumeReduction: null, //减容方式
        dASurgery: null, //手术是否成功
        dARunOff: null, //术后膝下Run-off评分
        dAVascular: null, //术后踝下血管评分
        daabi: null, //术后ABI
        dAImprovement: null, //症状改善情况
        useUmbrella: null, //是否使用保护伞
        umbrellaType: null, //保护伞类型
        umbrellaModel: null, //保护伞型号
        dAMinorComplications: null, //围手术期中小并发症
        dASeriousCom: null //围手术期严重并发症
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-table-tab {
  border-style: solid;
  border-width: medium;
  border-color: #ebeef5;
  display: flex;
  width: 99%;
  .info-table-left {
    width: 100%;
  }
  .info-table-right {
    margin-top: 10px;
    width: 100%;
    .input-form-left {
      border-style: solid;
      border-width: medium;
      border-color: #ebeef5;
    }
    .input-form-right {
      border-style: solid;
      border-width: medium;
      border-color: #ebeef5;
    }
  }
  .header {
    display: flex;
    padding-top: 5px;
    .title-left {
      width: 40%;
      text-align: center;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-right {
      width: 56%;
      text-align: center;
      min-height: 30px;
      line-height: 30px;
      padding-bottom: 5px;
      .header-button {
        width: 100px;
      }
    }
  }
}
.icon-finished::before {
  content: url('../../../assets/img/finished.png');
}
.icon-warn::before {
  content: url('../../../assets/img/warn.png');
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f9dddd !important;
}
/deep/.el-table__body tr.current-row > td.el-table__cell {
  background-color: #b8d4f0;
}
.save-button {
  padding-left: 10px;
  margin-right: 0.5rem;
}
.valve-module-main-content {
  .body::-webkit-scrollbar {
    width: 12px;
  }
  .body::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  .body::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  .body {
    flex: 1;
    overflow: auto;
    padding: 0 10px 20px;
    background: #eff0f7;

    .equipment_style {
      /deep/.el-input__inner {
        border: 0;
        padding: 0;
        text-align: center;
      }
      /deep/.el-input__inner {
        border: 1px solid black;
      }
      .input-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      .input-form-item-row {
        width: 100%;
        margin: 0 0.5rem;
        display: flex;
        justify-content: flex-start;
      }

      .input-form-item {
        min-width: 30%;
        margin: 0.4rem 1.5rem;
        display: flex;
        align-items: center;
        .input_has_unit {
          margin: 0;
        }
      }
      @media screen and (max-width: 1180px) {
        .input-form-item {
          min-width: 45%;
          margin: 0.4rem 1rem;
        }
      }

      .input-form-item-label {
        width: 120px;
      }
    }
    .button-panel {
      width: 100%;
      bottom: 0;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
    }
    .valve-basic-information {
      .valve-basic-information-body {
        .input-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        .input-form-item-row {
          width: 100%;
          margin: 0 0.5rem;
          display: flex;
          justify-content: flex-start;
        }

        .input-form-item {
          min-width: 30%;
          margin: 0.4rem 1.5rem;
          display: flex;
          align-items: center;
          .input_has_unit {
            margin: 0;
          }
        }
        @media screen and (max-width: 1180px) {
          .input-form-item {
            min-width: 40%;
          }
        }

        .input-form-item-label {
          width: 120px;
        }
      }
    }

    .pre-expansions-number {
      border-style: solid;
      border-width: medium;
      border-color: #ebeef5;
      margin-top: 30px;
      margin-bottom: 30px;

      .pre-expansions-head {
        button {
          margin-right: 0.5rem;
        }
        display: flex;
        .title-row {
          padding-left: 10px;
        }
        .title-number {
          padding-left: 10px;
        }
        .title-button {
          padding-left: 10px;

          // bug

          padding-bottom: 5px;
        }
      }
    }

    .post-expansions-number {
      border-style: solid;
      border-width: medium;
      border-color: #e4e7ed;
      margin-top: 30px;
      margin-bottom: 30px;

      .post-expansions-head {
        display: flex;
        button {
          margin-right: 0.5rem;
        }
        .title-row {
          padding-left: 10px;
        }
        .title-number {
          padding-left: 10px;
        }
        .title-button {
          padding-left: 10px;
          padding-bottom: 5px;
          padding-top: 5px;
        }
      }
    }
    .valve-implants-number {
      border-style: solid;
      border-width: medium;
      border-color: #dcdfe6;
      margin-top: 30px;
      margin-bottom: 30px;

      .valve-implants-number-head {
        display: flex;
        button {
          margin-right: 0.5rem;
        }
        .title-row {
          padding-left: 10px;
        }
        .title-number {
          padding-left: 10px;
        }
        .title-button {
          padding-left: 10px;
          padding-bottom: 5px;
          padding-top: 5px;
        }
      }
    }
  }
}
.valve-basic-information-muticards {
  margin-top: 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: medium;
  /* border-color: #c0c4cc; */
  border-color: #ecf0f1;
  padding: 18px 15px 18px 20px;
  border-radius: 5px;
  position: relative;
  background-color: #fff !important;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);

  .muticards {
    .el-card {
      margin: 10px 10px 10px 0;
    }

    .input-form-card {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .input-form-item {
      min-width: 30%;
    }

    .input-form-item {
      min-width: 28%;
      margin: 0.4rem 1.5rem;
      display: flex;
      align-items: center;

      .input_has_unit {
        width: 200px;
        margin: 0;
      }

      .input_has_unit_text {
        margin: 0;
        width: 280px;
      }

      .el-date-editor.el-input {
        width: 200px;
      }
    }

    .input-form-item {
      min-width: 30%;
      margin: 0.4rem 0 0.4rem 0.5rem;
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: 1180px) {
      .input-form-item {
        min-width: 40%;
      }
    }

    .input-form-item-label {
      width: 140px;
      display: flex;
    }

    .card-complication {
      .is-bordered {
        margin: 5px 10px 5px 0;
      }
    }
  }

  .valve-basic-label {
    margin: 15px;
    font-size: 16px;
    font-weight: bold;
  }

  .inner-title {
    position: absolute;
    top: -12px;
    left: 12px;
    background: #fff;
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
  }

  .sty-edit {
    display: flex;
    align-items: center;
  }

  .save-button {
    padding-left: 10px;
  }

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .input_has_xldl {
      /deep/.el-input__inner {
        border: none;
      }
    }

    .input_has_unit {
      margin: 0;
    }

    .input-form-item {
      margin: 0.4rem 0.5rem;
      display: flex;
      align-items: center;

      .input-form-item-label {
        text-align: center;
        width: 140px;
        display: flex;
      }
    }
  }

  .input-form-item {
    min-width: 28%;
    /* margin: 0.4rem 1.5rem; */
    margin: 0.4rem 0 0.4rem 0.5rem;
    display: flex;
    align-items: center;

    .input_has_unit {
      margin: 0;
    }

    .el-date-editor.el-input {
      width: 200px;
    }
  }

  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 40%;
    }
  }

  .input-form-item-label {
    width: 140px;
    display: flex;
  }

  .input-form-item-label-length {
    width: 150px;
  }
}
</style>
