<style scoped lang="less">
.valve-basic-information-body {
  display: flex;
  margin: 10px 0;
  justify-content: space-between;

  .el-card {
    /* margin: 0 10px; */
    margin-right: 10px;
  }

  .el-card:last-child {
    margin: 0;
  }

  .input-form-card {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .input-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .input-form-item {
    min-width: 28%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;

    .input_has_unit {
      width: 200px;
      margin: 0;
    }

    .input_has_unit_text {
      margin: 0;
      width: 280px;
    }

    .el-date-editor.el-input {
      width: 200px;
    }
  }

  .input-form-item {
    min-width: 30%;
    margin: 0.4rem 0 0.4rem 0.5rem;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1180px) {
    .input-form-item {
      min-width: 40%;
    }
  }

  .input-form-item-label {
    width: 120px;
  }
}
</style>

<template>
  <div class="valve-basic-information-body">
    <!-- 血管 -->
    <el-card style="width: 49.5%" :body-style="{ padding: '20px' }">
      <div class="input-form-card">
        <div class="input-form-item">
          <div class="input-form-item-label">血管入路</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/PB_puncture_site"
            :value.sync="aorticInfo.vascularApproach"
          />
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">入路方式</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/AO_road_method"
            :value.sync="aorticInfo.approachType"
          />
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">麻醉方式</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/AO_anesthesia"
            :value.sync="aorticInfo.anesthesiaType"
          />
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">造影剂</div>
          <div>
            <cqt-input
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              @input="getcontrastAgent"
              v-model="aorticInfo.contrastAgent"
              size="mini"
              unit="ml"
              min="0"
              max="300"
              :isReadonly="isReadonly"
            >
              <template slot="append">ml</template>
            </cqt-input>
          </div>
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">射线量</div>
          <div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              @input="getrayQuantity"
              v-model="aorticInfo.rayQuantity"
              size="mini"
              unit="mGy"
              min="0"
              max="500"
            >
              <template slot="append">mGy</template>
            </cqt-input>
          </div>
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">曝光时间</div>
          <div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              v-model="aorticInfo.exposureTime"
              size="mini"
              unit="min"
              min="0"
            >
              <template slot="append">min</template>
            </cqt-input>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 出血 -->
    <el-card style="width: 49.5%" :body-style="{ padding: '20px' }">
      <div class="input-form-card">
        <div class="input-form-item">
          <div class="input-form-item-label">是否出血</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/Yes_No"
            :value.sync="aorticInfo.isBleeding"
          />
        </div>
        <div class="input-form-item" v-if="aorticInfo.isBleeding === '是'">
          <div class="input-form-item-label">出血量</div>
          <div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              @input="getoutTransAmount"
              v-model="aorticInfo.outTransAmount"
              size="mini"
              unit="ml"
              min="0"
              max="5000"
            >
              <template slot="append">ml</template>
            </cqt-input>
          </div>
        </div>

        <!-- 输血 -->
        <Transfusion
          :isReadonly="isReadonly"
          ref="Transfusion"
          :apiOptions="apiOptions"
          :surgeryInfo="aorticInfo"
        />

        <div class="input-form-item">
          <div class="input-form-item-label">鱼精蛋白</div>
          <cqt-radio :value.sync="aorticInfo.protamine" :isReadonly="isReadonly">
            <el-radio label="有">有</el-radio>
            <el-radio label="无">无</el-radio>
          </cqt-radio>
        </div>
        <div class="input-form-item" v-show="aorticInfo.protamine && aorticInfo.protamine !== '无'">
          <div class="input-form-item-label">鱼精蛋白剂量</div>
          <div>
            <cqt-input
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              @input="getprotamineDose"
              v-model="aorticInfo.protamineDose"
              size="mini"
              unit="ml"
              min="0"
              max="500"
              :isReadonly="isReadonly"
            ></cqt-input>
          </div>
        </div>

        <!-- 血管闭合方式 -->
        <BloodCloseType
          :isReadonly="isReadonly"
          ref="BloodCloseType"
          :apiOptions="apiOptions"
          :surgeryInfo="aorticInfo"
        />

        <div class="input-form-item">
          <div class="input-form-item-label">术后制动</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/PB_BrakAreaTime"
            :value.sync="aorticInfo.postOperativeBraking"
          />
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">特殊操作</div>
          <cqt-select
            :isReadonly="isReadonly"
            activeUrl="/v1/webconsole/comboData/get/PB_Intervention"
            :value.sync="aorticInfo.intervention"
          />
        </div>
      </div>
    </el-card>
    <!-- 特殊操作 -->
    <!-- <el-card style="width: 25%" :body-style="{ padding: '20px' }">
      <div class="input-form-card">
        <div class="input-form-item">
          <div class="input-form-item-label">特殊操作</div>
          <cqt-select :isReadonly="isReadonly" activeUrl="/v1/webconsole/comboData/get/gmzy_jbxx_Intervention"
            :value.sync="aorticInfo.intervention" />
        </div>
      </div>
    </el-card> -->
  </div>
</template>

<script>
// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'

import BloodCloseType from '../../components/BloodCloseType.vue'
import Transfusion from '../../components/Transfusion.vue'

export default {
  name: 'BasicForm',
  components: {
    CqtInput,
    CqtSelect,
    CqtRadio,
    BloodCloseType,
    Transfusion
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false
    },
    basicData: {
      type: Object
    }
  },
  data() {
    return {
      liverCirrhosis_status: true,
      aorticInfo: {
        approachType: null,
        contrastAgent: null,
        coronaryRemark: null,
        coronaryRisk: null,
        anesthesiaType: null,
        details: null,
        diagnosis: null,
        embolicRemark: null,
        embolicRisk: null,
        exposureTime: null,
        vesslClosure: null,
        vesselObturator: null,
        isBmsl: null,
        isHkz: null,
        isYkz: null,
        isBleeding: '否', // 是否出血
        outTransAmount: null,
        protamine: null,
        protamineDose: null,
        rapidPacing: null,
        rayQuantity: null,
        recordCode: null,
        recordDate: null,
        recordName: null,
        riskOtherRemark: null,
        ruptureAnnulus: null,
        ruptureRemark: null,
        sheathGoInTime: null,
        sheathLength: null,
        sheathOutTime: '',
        sheathSize: null,
        shzd: null,
        remark: null,
        superhardGW: null,
        transfusion: null,
        transfusionAmount: null,
        transfusionComponent: null,
        nnisRating: null,
        ultrasonic: null,
        vascularApproach: null,
        coronaryDominantType: null,
        malformationOfCoronary: null,
        postOperativeBraking: null,
        // 定制化
        lesionSiteDirection: null, //病变部位方向
        lesionSite: null, //病变部位位置
        initialArterialApproach: null, //初始动脉入路
        initialArterySheath: null, //初始动脉鞘管
        reverseArterialDirection: null, //逆向动脉入路方向
        reverseArterialApproach: null, //逆向动脉入路位置
        reverseArterySheath: null, //逆向动脉鞘管
        byWay: null, //通过方式
        pathologicalFeatures: null, //病变特点
        isrblocking: null, //ISR阻塞分级
        bracketBroken: null, //支架是否发生折断
        breakType: null, //折断类型
        degreeOfCalcification: null, //钙化程度
        operationPosition: null, //手术部位
        operationName: null, //手术方式
        thrombus: null, //有无血栓
        vesslClosureOther: null
      },
      ISRIsExitHover: false, //是否悬浮到图标上
      isrblockingExitActive: require('@/assets/img/nerve/ISRzuse.png'),
      breakType_status: true,
      breakIsExitHover: false, //是否悬浮到图标上
      breakTypeExitActive: require('@/assets/img/nerve/break.png'),
      CalcificationIsExitHover: false, //是否悬浮到图标上
      calcificationExitActive: require('@/assets/img/nerve/calcification.png'),
      apiOptions: {
        vesslClosureUrl: 'PB_vessel_closure', // 血管闭合方式
        vesselObturatorUrl: 'PB_vessel_obturator', // 闭合器
        occluder_typeUrl: 'occluder_type', // 封堵器
        stapler_typeUrl: 'stapler_type', // 缝合器
        transfusionComponentUrl: 'AO_composition_blood_transfusion' // 输血成分
      }
    }
  },
  computed: {},
  watch: {
    basicData: {
      handler(val) {
        this.aorticInfo = { ...this.aorticInfo, ...val }
        if (this.aorticInfo?.bracketBroken === '是') {
          this.breakType_status = false
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {},
  created() {},
  methods: {
    changeBracketBroken(val) {
      if (val === '否') {
        this.aorticInfo.breakType = null
        this.breakType_status = true
      } else {
        this.breakType_status = false
      }
    },
    changeLiverCirrhosis(val) {
      if (this.isReadonly) return
      if (val === '否') {
        this.aorticInfo.cirrhosisEtiology = ''
        this.liverCirrhosis_status = true
      } else {
        this.liverCirrhosis_status = false
      }
    },
    geiSheathOutTime(val) {
      if (!this.aorticInfo.sheathGoInTime) return
      const time = this.differMin(this.aorticInfo.sheathGoInTime, val)
      if (time < 0) {
        this.aorticInfo.sheathOutTime = null
        this.$message({
          message: '鞘拔除时间应大于鞘置入时间',
          type: 'warning'
        })
      }
    },
    getcontrastAgent() {
      if (this.aorticInfo.contrastAgent) {
        if (this.aorticInfo.contrastAgent > 300) this.aorticInfo.contrastAgent = 300

        if (this.aorticInfo.contrastAgent < 0) this.aorticInfo.contrastAgent = 0
      }
    },
    getrayQuantity() {
      if (this.aorticInfo.rayQuantity) {
        if (this.aorticInfo.rayQuantity > 500) this.aorticInfo.rayQuantity = 500

        if (this.aorticInfo.rayQuantity < 0) this.aorticInfo.rayQuantity = 0
      }
    },
    getoutTransAmount() {
      if (this.aorticInfo.outTransAmount) {
        if (this.aorticInfo.outTransAmount > 5000) this.aorticInfo.outTransAmount = 5000

        if (this.aorticInfo.outTransAmount < 0) this.aorticInfo.outTransAmount = 0
      }
    },
    getprotamineDose() {
      if (this.aorticInfo.protamineDose) {
        if (this.aorticInfo.protamineDose > 500) this.aorticInfo.protamineDose = 500

        if (this.aorticInfo.protamineDose < 0) this.aorticInfo.protamineDose = 0
      }
    },
    getAllBasicData() {
      this.aorticInfo = {
        ...this.aorticInfo,
        ...this.$refs.BloodCloseType.aorticInfo,
        ...this.$refs.Transfusion.aorticInfo
      }
      return this.aorticInfo
    }
  }
}
</script>
